import * as Yup from 'yup';

export const validationSchema = Yup.object({
  eventName: Yup.string()
    .required('Event name is required')
    .max(20, 'Event name must be less than 20 characters'),
  eventUrl: Yup.string().required('Event url is required'),
  customer: Yup.string().required('Customer is required'),
  date: Yup.string().required('Date is required'),
  time: Yup.string().required('Time is required'),
  duration: Yup.number()
    .min(0, 'Values from 0 to 600')
    .max(600, 'Values from 0 to 600')
    .required('Duration is required'),
  coordinatorName: Yup.string().required('Coordinator name is required'),
  coordinatorEmail: Yup.string()
    .email('Wrong email format')
    .required('Coordinator email is required'),
  coordinatorPassword: Yup.string()
    .min(3, 'Minimun length is 3 symbols')
    .required('Coordinator pass is required'),
  gameId: Yup.number().required('Game is required'),
  teamsNumber: Yup.number()
    .required('Number of teams is required')
    .min(0, 'Values from 0 to 100')
    .max(100, 'Values from 0 to 100'),
  description: Yup.string(),
});