import { gql } from '@apollo/client';

export const GET_EVENT_TEAMS_COLORS = gql`
    query ev($eventId: Int!) {
        eventTeamsColors (eventId: $eventId) {
            events {
                teamEvents {
                    teamId
                    team {
                        name
                    }
                    color
                }
            }
        }
    }
`;
