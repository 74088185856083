import { gql } from '@apollo/client';

export const GET_COORDINATOR_EVENT = gql`
    query coordinatorEvent($url: String!) {
        coordinatorEvent(url: $url) {
            id
            name
            startDatetime
            teamsLimit
            duration
            state
            isCanceled
            maxPlayersInTeam
            rates {
                teamName
                score
                solvedPuzzlesCount
                passedPuzzlesCount
                usedHintsCount
                totalTime
            }
            teamEvents {
                id
                score
                totalTime
                url
                team {
                    id
                    name
                    description
                    members {
                        id
                        name
                    }
                }
                puzzleStats {
                    id
                    answeredAt
                    passedAt
                    puzzle {
                        id
                        name
                    }
                    mistakes {
                        id
                        createdAt
                        text
                    }
                    usedHints {
                        id
                        createdAt
                    }
                }
            }
            coordinator {
                id
                login
                email
                urlIdentity
            }
            game {
                id
                name
                image
                topImage
                finalImage
                bgImage
                beginningText
                waitingText
                waitingImage
                puzzles {
                    id
                    name
                    file
                    order
                    format
                    answer
                    hints {
                        text
                        order
                    }
                }
            }
        }
    }
`;

