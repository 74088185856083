import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Formik } from 'formik';
import { validationSchema } from './editValidation';
import moment from 'moment';
import CancelDialog from './cancelDialog';
import SaveDialog from './saveDialog';
import { useMutation } from '@apollo/client';
import { CANCEL_EVENT } from '../../operations/mutations/cancelEvent';
import Notify from '../../utils/notify';
import { EDIT_EVENT } from '../../operations/mutations/editEvent';
import { parseError } from '../../utils/parseGraphqlError';
import apolloClient from '../../utils/apolloClient';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EventEditForm = (
  {
    games,
    setEventToEdit,
    event,
    refetchEvents,
  },
) => {
  const [isCancelPopupOpen, setCancelPopupOpen] = useState(false);
  const [isChangePasswordPopupOpen, setChangePasswordPopupOpen] = useState(false);
  const [errorHandler, setErrorHandler] = useState(null);
  const [eventVariables, setEventVariables] = useState(null);

  const handleClose = () => {
    setEventToEdit(null);
  };

  const [cancelEventMutation] = useMutation(CANCEL_EVENT, {
    onCompleted: () => {
      Notify.cancelEvent();
      refetchEvents();
      setCancelPopupOpen(false);
      setEventToEdit(null);
    },
  });

  const [editEventMutation] = useMutation(EDIT_EVENT, {
    onError: err => {
      const { field, text } = parseError(err);
      errorHandler(field, text);
      setErrorHandler(null);
      setEventVariables(null);
      setChangePasswordPopupOpen(false);
    },
    onCompleted: () => {
      refetchEvents();
      Notify.editEvent();
      setEventToEdit(null);
    },
  });

  if (!event) return null;

  return (
    <Dialog
      fullScreen
      open={!!event}
      onClose={handleClose}
      TransitionComponent={Transition}
	    disableScrollLock
    >
      <AppBar className="app-bar">
        <Container>
          <Toolbar className="toolbar">
            <Typography variant="h6">
              Edit Event
            </Typography>

            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>

      <Container className="add-event-form">
        <Formik
          initialValues={{
            eventName: event.name,
            eventUrl: event.coordinator.urlIdentity,
            customer: event.customer,
            date: moment(new Date(Number(event.startDatetime))).format('YYYY-MM-DD'),
            time: moment(new Date(Number(event.startDatetime))).format('HH:mm'),
            duration: event.duration / 60 / 1000,
            coordinatorName: event.coordinator.login,
            coordinatorEmail: event.coordinator.email,
            gameId: event.gameId,
            teamsNumber: event.teamsLimit,
            description: event.description || '',
            coordinatorPassword: '',
            slackChannelName: event.slackChannelName,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setFieldError }) => {
            const data = {
                id: event.id,
                name: values.eventName,
                url: values.eventUrl,
                customer: values.customer,
                startDatetime: new Date(`${values.date} ${values.time}`),
                duration: values.duration * 60 * 1000,
                coordinatorName: values.coordinatorName,
                coordinatorEmail: values.coordinatorEmail,
                coordinatorPassword: values.coordinatorPassword,
                gameId: +values.gameId,
                teamsLimit: values.teamsNumber,
                description: values.description,
                slackChannelName: values.slackChannelName,
              };
            if (values.coordinatorPassword.length) {
              setErrorHandler(setFieldError);
              setEventVariables(data);
              setChangePasswordPopupOpen(true);
            } else {
              apolloClient.mutate({
                mutation: EDIT_EVENT,
                variables: data,
              }).then(() => {
                Notify.editEvent();
                refetchEvents();
                setEventToEdit(null);
              }).catch(e => {
                const { field, text } = parseError(e);
                setFieldError(field, text);
              });
            }
          }}>
          {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Box className="form-wrap" display="flex" flexDirection="column">
                  <Box display="flex">
                    <Box>
                      <TextField
                        variant="outlined"
                        helperText={(touched.eventName && errors.eventName) && errors.eventName}
                        name="eventName"
                        label="Event Name"
                        error={(touched.eventName && errors.eventName)}
                        value={values.eventName}
                        onChange={handleChange}
                      />
                    </Box>

                    <Box>
                      <TextField
                        variant="outlined"
                        helperText={(touched.eventUrl && errors.eventUrl) && errors.eventUrl}
                        name="eventUrl"
                        label="Event URL"
                        error={(touched.eventUrl && errors.eventUrl)}
                        value={values.eventUrl}
                        onChange={handleChange}
                      />
                    </Box>

                    <Box>
                      <TextField
                        variant="outlined"
                        helperText={(touched.customer && errors.customer) && errors.customer}
                        name="customer"
                        label="Customer"
                        error={(touched.customer && errors.customer)}
                        value={values.customer}
                        onChange={handleChange}
                      />
                    </Box>
                  </Box>

                  <Box display="flex">
                    <Box className="datetime-wrap">
                      <TextField
                        variant="outlined"
                        helperText={(touched.date && errors.date) && errors.date}
                        type="date"
                        value={values.date}
                        name="date"
                        error={(touched.date && errors.date)}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>

                    <Box className="datetime-wrap">
                      <TextField
                        variant="outlined"
                        type="time"
                        value={values.time}
                        name="time"
                        helperText={(touched.time && errors.time) && errors.time}
                        error={(touched.time && errors.time)}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300,
                        }}
                      />
                    </Box>

                    <Box>
                      <TextField
                        variant="outlined"
                        helperText={(touched.duration && errors.duration) && errors.duration}
                        name="duration"
                        type="number"
                        label="Event Duration"
                        error={(touched.duration && errors.duration)}
                        value={values.duration}
                        onChange={handleChange}
                      />
                    </Box>
                  </Box>

                  <Box display="flex">
                    <Box>
                      <TextField
                        variant="outlined"
                        helperText={(touched.coordinatorName && errors.coordinatorName) && errors.coordinatorName}
                        name="coordinatorName"
                        label="Coordinator Name"
                        error={(touched.coordinatorName && errors.coordinatorName)}
                        value={values.coordinatorName}
                        onChange={handleChange}
                      />
                    </Box>

                    <Box>
                      <TextField
                        variant="outlined"
                        helperText={(touched.coordinatorEmail && errors.coordinatorEmail) && errors.coordinatorEmail}
                        name="coordinatorEmail"
                        label="Coordinator Email"
                        error={(touched.coordinatorEmail && errors.coordinatorEmail)}
                        value={values.coordinatorEmail}
                        onChange={handleChange}
                      />
                    </Box>

                    <Box>
                      <TextField
                        variant="outlined"
                        helperText={(touched.coordinatorPassword && errors.coordinatorPassword) && errors.coordinatorPassword}
                        name="coordinatorPassword"
                        type="password"
                        label="Coordinator Password"
                        error={(touched.coordinatorPassword && errors.coordinatorPassword)}
                        value={values.coordinatorPassword}
                        onChange={handleChange}
                      />
                    </Box>
                  </Box>

                  <Box display="flex">
                    <Box>
                      <FormControl variant="outlined" className="form-control">
                        <InputLabel id="demo-simple-select-outlined-label">Game</InputLabel>
                        <Select
                          value={values.gameId}
                          onChange={handleChange}
                          error={(touched.gameId && errors.gameId)}
                          label="Game"
                          name="gameId"
                        >
                          {games.map((elem, index) => (
                            <MenuItem key={index} value={elem.id}>{elem.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>

                    <Box>
                      <TextField
                        variant="outlined"
                        helperText={(touched.teamsNumber && errors.teamsNumber) && errors.teamsNumber}
                        name="teamsNumber"
                        type="number"
                        label="Number of teams"
                        error={(touched.teamsNumber && errors.teamsNumber)}
                        value={values.teamsNumber}
                        onChange={handleChange}
                      />
                    </Box>
                    
                    <Box>
                        <TextField
                          variant="outlined"
                          name="slackChannelName"
                          label="Slack channel name"
                          value={values.slackChannelName}
                          onChange={handleChange}
                          onChange={(e) => {
                            setFieldValue('slackChannelName', e.target.value.replaceAll(' ', '-'));
                          }}
                          helperText={errors.slackChannelName}
                          error={errors.slackChannelName}
                        />
                      </Box>
                      
                  </Box>

                  <Box className="textarea-wrap">
                    <TextField
                      label="Notes"
                      multiline
                      className="textarea-input"
                      rows={4}
                      value={values.description}
                      onChange={handleChange}
                      name="description"
                      variant="outlined"
                      helperText={(touched.description && errors.description) && errors.description}
                      error={(touched.description && errors.description)}
                    />
                  </Box>

                  <Box className="action-btns">
                    <Button
                      onClick={() => {
                        setCancelPopupOpen(true);
                      }}
                      size="large"
                      variant="contained"
                      color="secondary"
                      className="add-event-save-btn"
                    >
                      Cancel Event
                    </Button>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      className="add-event-save-btn"
                    >
                      Edit
                    </Button>
                  </Box>
                </Box>
              </form>
            );
          }}
        </Formik>
      </Container>


      <CancelDialog
        isPopupOpen={isCancelPopupOpen}
        setPopupOpen={setCancelPopupOpen}
        cancelEventMutation={cancelEventMutation}
        eventId={event.id}
      />
      <SaveDialog
        isPopupOpen={isChangePasswordPopupOpen}
        setPopupOpen={setChangePasswordPopupOpen}
        editEventMutation={editEventMutation}
        eventVariables={eventVariables}
      />
    </Dialog>
  );
};

export default EventEditForm;
