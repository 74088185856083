import React, { useState, useEffect } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import './style.css';
import { validationSchema } from './validation';
import { Formik, FormikHelpers } from 'formik';
import HintButton from '../HintButton/HintButtonComponent';
import GiveUpButton from '../GiveUpButton/GiveUpButtonComponent';
import { Fade, Slide } from "react-awesome-reveal";

export interface IAnswerForm {
  handleAnswerCheck: (answer: string, action: FormikHelpers<{ answer: string; }>) => void
  isBlocked: boolean
  usedHints: string[]
  eventStartDateTime: Date
  lastHintUseDateTime: string
  handleUseHint: () => void
  handleGiveUp: (resetForm: () => void) => void
  availableHintsCount: number
}

const AnswerForm: React.FC<IAnswerForm> = (
  {
    handleAnswerCheck,
    isBlocked,
    usedHints,
    availableHintsCount,
    eventStartDateTime,
    lastHintUseDateTime,
    handleUseHint,
    handleGiveUp,
  },
) => {
  let hintRef = React.createRef<HTMLDivElement>();
  const countOfHints= 0;
  const [hintUsed, setHintUsed] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const PreHandleUseHint = () => {
    handleUseHint();
    setHintUsed(true);
  }

  useEffect(() => {
    setShowButton(false);
    setTimeout(function() {
      setShowButton(true);
    }, 0);
  }, [usedHints.length, availableHintsCount])

  useEffect(() => {
    if (usedHints.length == 0) {
      setHintUsed(false);
    }
    if (countOfHints !== usedHints.length) {
      if (hintRef.current && hintRef.current !== null && hintUsed) {
        window.scrollTo({
          top: hintRef?.current?.getBoundingClientRect().top + window.pageYOffset,
          behavior: "smooth",
        });
      }
    }
  }, [usedHints.length])

  return (
    <Formik
      initialValues={{ answer: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        handleAnswerCheck(values.answer, actions);
        actions.resetForm();
      }}
    >
      {({
          values,
          errors,
          handleChange,
          handleSubmit,
          resetForm,
        }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box display="flex">
              <Box mr={2}>
                <TextField
                  variant="outlined"
                  helperText={errors.answer && errors.answer}
                  name="answer"
                  type="answer"
                  placeholder="Answer"
                  error={!!(errors.answer)}
                  value={values.answer}
                  onChange={handleChange}
                  disabled={isBlocked}
                />
              </Box>

              <Button
                disabled={isBlocked}
                type="submit"
                data-id="answer"
                size="large"
                variant="contained"
                className="puzzle-btn"
                color="primary">
                Enter
              </Button>
              {isBlocked && (
                <Box className="puzzle-block" pl={3}>
                  Blocked for {process.env.REACT_APP_ANSWER_FORM_BLOCKING_TIME} sec
                </Box>
              )}
            </Box>
            {usedHints.map((hint, index) => (
              <Fade duration={2000} triggerOnce>
              <Slide duration={1000} direction="up" triggerOnce>
              <Box display="flex" width="100%" flexDirection="column" key={index}>
                <Box mt={2}>
                  <Box>
                    <Box>
                      <div ref={usedHints.length == 1 ? hintRef : ((index == usedHints.length-1) ? hintRef : null)}>
                        <Box className="hint-status" data-index={index} data-HintLength={usedHints.length} data-give={(index == usedHints.length-1) ? "Yes" : "No"}>Hint {index + 1} </Box>
                      </div>
                      <Box className="hint-text">"{hint}"</Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              </Slide>
              </Fade>
            ))}
            {showButton ? (<Fade delay={1000} duration={1000} triggerOnce>{usedHints.length === availableHintsCount ? (
                <GiveUpButton
                  eventStartDateTime={eventStartDateTime}
                  lastHintUseDateTime={lastHintUseDateTime ? new Date(+lastHintUseDateTime) : null}
                  handleGiveUp={handleGiveUp}
                  resetForm={resetForm}
                />
              ) : (
              <HintButton
                hintNumber={usedHints.length + 1}
                eventStartDateTime={eventStartDateTime}
                lastHintUseDateTime={lastHintUseDateTime ? new Date(+lastHintUseDateTime) : null}
                handleUseHint={PreHandleUseHint}
              />
            )}</Fade>) : "" }
          </form>
        );
      }}
    </Formik>
  );
};

export default AnswerForm;
