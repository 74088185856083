import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import CheckIcon from '@material-ui/icons/Check';
import TimerIcon from '@material-ui/icons/Timer';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FunctionsIcon from '@material-ui/icons/Functions';
import { useQuery } from '@apollo/client';
import { GET_EVENT_TEAMS_COLORS } from '../../../operations/queries/getEventTeamsColors';
import {
  formatFinalTime,
  getPlaceEnding,
  getPlaceColor,
} from '../../../utils/util';
import uuid from 'react-uuid';

const CoordinatorRates = ({ event, gameState }) => {

  const [teamColorsUploaded, setTeamColorsUploaded] = useState(false);
  const [teamColors, getTeamsColors] = useState(new Map());
  const eventId = event.id;
 
  const {
    loading,
    data,
    error,
  } = useQuery(GET_EVENT_TEAMS_COLORS, {
    variables: { eventId },
    skip: teamColorsUploaded
  });
  
  useEffect(() => {
    if (!teamColorsUploaded) {
      if (data) {
        let d = { data };
        let teamEvents = d.data.eventTeamsColors.events[0].teamEvents;
        for (let i = 0; i < teamEvents.length;i++) {
          teamColors.set(teamEvents[i].team.name, teamEvents[i].color);
        }
        setTeamColorsUploaded(true);
      }
    }
  })

  return (
    <Box className="leaderboard">
      <Box className="lead-title">
        Leaderboard
      </Box>

      <Grid container spacing={3}>
        {event.rates.map((rate, index) => (
          <Grid item xs={12} md={6} lg={4} key={uuid()}>
            <Paper elevation={3} className="rate-popup-item">
              <Box
                px={2}
                py={1}
                style={{ backgroundColor: teamColors.get(rate.teamName) ? teamColors.get(rate .teamName) : getPlaceColor(index + 1)}}
                className="rate-top"
                data-ranked={rate.solvedPuzzlesCount > 0 ? "true" : "false"}>
                {rate.solvedPuzzlesCount > 0 ? `${index + 1}${getPlaceEnding(index + 1)} Place` : "" }
              </Box>
              <Box px={2} py={1}>
                <Box className="rate-line" py={1}>
                  <GroupIcon className="rate-icon" />
                  Team: <span>{rate.teamName}</span>
                </Box>
                <Box className="rate-line" py={1} borderTop={1} borderColor={'#ddd'}>
                  <FunctionsIcon className="rate-icon" />
                  Score: <span>{rate.score}</span>
                </Box>
                <Box className="rate-line" py={1} borderTop={1} borderColor={'#ddd'}>
                  <CheckIcon className="rate-icon" />
                  Puzzles Solved: <span>{rate.solvedPuzzlesCount}/{event.game.puzzles.length}</span>
                </Box>
                <Box className="rate-line" py={1} borderTop={1} borderColor={'#ddd'}>
                  <ClearIcon className="rate-icon" />
                  Questions Passed: <span>{rate.passedPuzzlesCount}</span>
                </Box>
                <Box className="rate-line" py={1} borderTop={1} borderColor={'#ddd'}>
                  <VisibilityIcon className="rate-icon" />
                  Hints Used: <span>{rate.usedHintsCount}</span>
                </Box>
                <Box className="rate-line" py={1} borderTop={1} borderColor={'#ddd'}>
                  <TimerIcon className="rate-icon" />
                  Final Time: 
                  <span>
                    {rate.totalTime ? formatFinalTime(rate.totalTime) : (gameState == 'FINISHED' ? 'Did not finish' : 'Game in progress')}
                  </span>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CoordinatorRates;
