import React from 'react';
import CoordinatorForm from '../../components/CoordinatorForm'

const CoordinatorLogin = () => {
    return (
        <div>
            <CoordinatorForm/>
        </div>
    );
}

export default CoordinatorLogin;
