import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default ({ children, user, role, ...rest }) => {
  return (
    <Route {...rest} render={({ location }) => {
      if (user && user.role === role) {
        return children;
      }

      return <Redirect to={{ pathname: '/no-permission', state: { from: location } }} />;
    }} />
  );
}
