import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import { Box } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FieldArray, Formik } from 'formik';
import RemoveDialog from './removeDialog';
import { validationSchema } from './editValidation';
import { useMutation } from '@apollo/client';
import Notify from '../../utils/notify';
import { REMOVE_TEAM } from '../../operations/mutations/removeTeam';
import apolloClient from '../../utils/apolloClient';
import { parseError } from '../../utils/parseGraphqlError';
import { EDIT_TEAM } from '../../operations/mutations/editTeam';


export default (
  {
    isPopupOpen,
    setPopupOpen,
    teamEvent,
    teamEvents,
    eventRefetch,
  },
) => {
  const [isRemovePopupOpen, setRemovePopupOpen] = useState(false);

  const [removeTeamMutation] = useMutation(REMOVE_TEAM, {
    onCompleted: () => {
      eventRefetch();
      Notify.removeTeam();
      setRemovePopupOpen(false);
      setPopupOpen(null);
    },
  });

  if (!teamEvent) {
    return null;
  }
  return (
    <div>
      <Dialog
        disableBackdropClick={true}
        open={isPopupOpen}
        onClose={() => setPopupOpen()}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
		    disableScrollLock
      >
        <DialogTitle id="form-dialog-title">
          Edit Team
        </DialogTitle>

        <Formik
          className="team-form"
          initialValues={{
            teamName: teamEvent.team.name,
            members: teamEvent.team.members.map(member => ({
              id: member.id,
              name: member.name,
            })),
            notes: teamEvent.team.description,
          }}
          validationSchema={validationSchema}
          onSubmit={({teamName, notes, members}, {setFieldError}) => {
            if (teamEvents.some(teamEvent => teamEvent.team.name === teamName) && teamName !== teamEvent.team.name) {
              setFieldError('teamName', 'Team name should be unique');
              return;
            }

            apolloClient.mutate({
              mutation: EDIT_TEAM,
              variables: {
                name: teamName,
                notes: notes,
                id: teamEvent.team.id,
                members: members,
              },
            }).then(() => {
              eventRefetch();
              setPopupOpen(false);
              Notify.editTeam();
            }).catch(e => {
              const { field, text } = parseError(e);
              setFieldError(field, text);
            });
          }}>
          {({ values, errors, touched, handleChange, handleSubmit, setValues }) => {
            return (
              <>
                <DialogContent className="add-team-body">
                  <form onSubmit={handleSubmit}>
                    <Box display="flex" flexDirection="column">
                      <Box>
                        <TextField
                          fullWidth
                          variant="outlined"
                          helperText={(touched.teamName && errors.teamName) && errors.teamName}
                          name="teamName"
                          label="Team Name"
                          error={Boolean(errors.teamName) && touched.teamName}
                          value={values.teamName}
                          onChange={handleChange}
                        />
                      </Box>

                      <Box className="team-form-title">Team Members</Box>

                      <FieldArray name={'members'}>
                        {(arrayHelpers) => (
                          values.members.map((elem, index) => (
                            <Box key={index} className="member-input">
                              <TextField
                                fullWidth
                                helperText={
                                  !index
                                  && touched
                                  && touched.members
                                  && touched.members[index]
                                  && errors
                                  && errors.members
                                  && errors.members[index]
                                  && errors.members[index].name
                                }
                                name={`members.${index}.name`}
                                placeholder={'Team Member ' + (index + 1)}
                                error={
                                  touched
                                  && touched.members
                                  && touched.members[index]
                                  && !!errors
                                  && !!errors.members
                                  && !!errors.members[index]
                                  && !!errors.members[index].name
                                }
                                value={values.members[index].name}
                                onChange={handleChange}
                              />
                            </Box>
                          ))
                        )}
                      </FieldArray>

                      <Box className="team-form-title">Team Notes</Box>

                      <Box className="member-input notes">
                        <TextField
                          fullWidth
                          helperText={(touched.notes && errors.notes) && errors.notes}
                          name="notes"
                          placeholder="Notes"
                          rows={4}
                          multiline
                          variant="outlined"
                          error={(touched.notes && errors.notes)}
                          value={values.notes}
                          onChange={handleChange}
                        />
                      </Box>
                    </Box>
                  </form>
                </DialogContent>

                <DialogActions>
                  <Button
                    className="cancel-btn"
                    onClick={() => setPopupOpen(null)}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button onClick={() => setRemovePopupOpen(true)} color="primary">
                    Remove
                  </Button>
                  <Button onClick={handleSubmit} color="primary">
                    Edit
                  </Button>
                </DialogActions>
              </>
            );
          }}
        </Formik>

        <RemoveDialog
          isPopupOpen={isRemovePopupOpen}
          setPopupOpen={setRemovePopupOpen}
          removeTeamMutation={removeTeamMutation}
          id={teamEvent.team.id}
        />
      </Dialog>
    </div>
  );
}


