import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

export default (
  {
    editEventMutation,
    setPopupOpen,
    isPopupOpen,
    eventVariables,
  },
) => (
  <Dialog
	  disableScrollLock
    open={isPopupOpen}
    onClose={() => setPopupOpen(false)}>
    <DialogTitle id="alert-dialog-slide-title">
      Edit Event
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        Are you sure you want to change Coordinator's password for the event?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => setPopupOpen(false)}
        color="primary"
      >
        Disagree
      </Button>
      <Button
        onClick={() => {
          editEventMutation({ variables: eventVariables });
          setPopupOpen(false);
        }}
        color="primary"
      >
        Agree
      </Button>
    </DialogActions>
  </Dialog>
);
