import React, { useEffect, useState } from 'react';
import {Box, Container} from '@material-ui/core';
import "./style.css"
import FlipCountdown from '@rumess/react-flip-countdown';
import clockIcon from '../../../img/clock_sm.png'
import teamIcon from '../../../img/team_sm.png';

interface IGameWaiting {
  startEventDateTime: Date
  endEventDateTime: Date
  teamsCount: number
  waitingText: string
  waitingImageUrl: string
}

const GameWaiting: React.FC<IGameWaiting> = (
      {
        startEventDateTime,
        endEventDateTime,
        teamsCount,
        waitingText,
        waitingImageUrl,
      }
    ) => 
  {
    
    let startAt = new Date(+startEventDateTime);
    const [countDownTo, setCountDownTo] = useState('00:00:00')
    
    useEffect(() => {
      setCountDownTo(startAt.toString());
    }, [startAt])
    
  return (
    <Box className="waiting-block" display="flex" alignItems="center" width="100%" flexDirection="column">
        {startAt.getTime() > Date.now() && (
          <Container maxWidth="md" style={{ padding: 0 }}>
          <Box>
            <Box className="waiting-block-header" width="100%" border={1} borderColor="#ddd">
              <Box className="start-in">
                <span className="start-in-text">Game Starts in</span>
                <FlipCountdown
                  dayTitle='Days'
                  hourTitle='Hours'
                  minuteTitle='Minutes'
                  secondTitle='Seconds'
                  hideYear
                  hideMonth
                  size='medium'
                  titlePosition='bottom'
                  endAt={countDownTo}
                />
              </Box>
              <Box className="game-waiting-image">
                <img src={`${process.env.REACT_APP_IMAGE_PATH}img/${waitingImageUrl}`} alt="Game image" />
              </Box>
            </Box>
            <Box className="game-waiting-wrapper">
              <Box className="game-waiting-content">
                <div id="flipdown" className="flipdown"></div>
                <Box className="game-waiting-content-header">
                  <Box className="waiting-content-header-time">
                    <img className="waiting-content-header-icon" src={clockIcon} alt="hourglass" />
                    <span>{(Math.abs(endEventDateTime.getTime() - startEventDateTime.getTime())/60000).toString()} minutes</span>
                  </Box>
                  <Box className="waiting-content-header-teams">
                    <img className="waiting-content-header-icon" src={teamIcon} alt="teams" />
                    <span>{teamsCount} Teams Playing</span>
                  </Box>
                </Box>
                <Box className="game-waiting-content-body"  dangerouslySetInnerHTML={{__html: waitingText}}/>
              </Box>
            </Box>
          </Box>
        </Container>
        )}
    </Box>
  );
}

export default GameWaiting;
