import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Grid,
  Paper,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import GroupIcon from '@material-ui/icons/Group';
import CheckIcon from '@material-ui/icons/Check';
import TimerIcon from '@material-ui/icons/Timer';
import ClearIcon from '@material-ui/icons/Clear';
import FunctionsIcon from '@material-ui/icons/Functions';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  formatFinalTime,
  getPlaceColor,
  getPlaceEnding,
} from '../../../utils/util';
import './style.css';
import { useQuery } from '@apollo/client';
import { GET_EVENT_TEAMS_COLORS } from '../../../operations/queries/getEventTeamsColors';
import { IRate } from '../../../InterfacesFromDB';

interface ITeamRatesPopups {
  gameState: string
  teamRates: IRate[]
  eventId: number
  requestToRefetch: () => void
}

const TeamsRate: React.FC<ITeamRatesPopups> = (
  {
    gameState,
    teamRates,
    eventId,
    requestToRefetch,
  },
  ) => {
    const [isRatePopupOpen, setRatePopupOpen] = useState(false);
    const [teamColorsUploaded, setTeamColorsUploaded] = useState(false);
    const [teamColors, setTeamsColors] = useState(new Map());

    useEffect(() => {
      if (isRatePopupOpen) {
        requestToRefetch()
      }
    }, [isRatePopupOpen])
    
    const {
      loading,
      data,
      error,
    } = useQuery(GET_EVENT_TEAMS_COLORS, {
      variables: { eventId },
      skip: teamColorsUploaded,
      onCompleted: () => {
        if (data) {
          let d = { data };
          let teamEvents = d.data.eventTeamsColors.events[0].teamEvents;
    
          let tempTeamColors = new Map();
          
          for (let i = 0; i < teamEvents.length;i++) {
            tempTeamColors.set(teamEvents[i].team.name, teamEvents[i].color);
          }
          setTeamsColors(tempTeamColors);
          setTeamColorsUploaded(true);
        }
      }
    });

  return (
    <Box>
      {gameState !== 'UPCOMING' ? (
        <Box className="rates-block">
          <Button
            className="mobile-rates"
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setRatePopupOpen(true)}
          >
            Leaderboard
          </Button>
          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
            className="top-rate-block"
          >
            <Box pr={3}>
              <Typography variant="h5" className="top-rate-title">
                Top Teams
              </Typography>
            </Box>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setRatePopupOpen(true)}
            >
              Leaderboard
            </Button>
          </Box>
          <Box className="rates-item-wrap" display="flex" justifyContent="space-between">
            <Box className="header-background-1">
              <img src={`${process.env.REACT_APP_IMAGE_PATH}img/topBG_image1.jpg`} alt='' />
            </Box>

            {teamRates.map((teamRate, index) => {
              if (index >= 3) return null;
              return (
                  <Box
                    flexGrow={1}
                    className="rates-item"
                    border={1}
                    key={index}
                    borderColor="#ccc"
                    ml={index > 0 ? 1 : 0}
                    onClick={() => setRatePopupOpen(true)}
                    onMouseEnter={() => { document.body.style.cursor = "pointer"; }}
                    onMouseLeave={() => { document.body.style.cursor = "default"; }}
                  >
                    <Box
                      style={{ backgroundColor: teamColors.get(teamRate.teamName) ? teamColors.get(teamRate.teamName) : getPlaceColor(index + 1)}}
                      textAlign="center"
                      className="rates-item-title"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      data-ranked={teamRate.solvedPuzzlesCount > 0 ? "true" : "false"}
                    >
                      {(index === 0 && teamRate.solvedPuzzlesCount > 0) ? <StarIcon className="star-icon" /> : null}
                      {teamRate.solvedPuzzlesCount > 0 ? `${index + 1}${getPlaceEnding(index + 1)} Place` : "" }
                    </Box>
                    <Box textAlign="center" className="rates-item-body">
                      {teamRate.teamName}
                    </Box>
                  </Box>
              );
            })}
          </Box>
        </Box>
      ) : null}

      {/* Teams Rate Popup */}
      <Dialog
        onClose={() => setRatePopupOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={isRatePopupOpen}
        maxWidth="lg"
        fullWidth
		    disableScrollLock
      >
        <DialogTitle id="customized-dialog-title">
          Leaderboard
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            {teamRates.map((teamRate, index) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <Paper elevation={3} className="rate-popup-item">
                  <Box
                    px={2}
                    py={1}
                    style={{ backgroundColor: teamColors.get(teamRate.teamName) ? teamColors.get(teamRate.teamName) : getPlaceColor(index + 1)}}
                    className="rate-top"
                    data-ranked={teamRate.solvedPuzzlesCount > 0 ? "true" : "false"}
                  >
                    {teamRate.solvedPuzzlesCount > 0 ? `${index + 1}${getPlaceEnding(index + 1)} Place` : "" }
                  </Box>
                  <Box px={2} py={1}>
                    <Box className="rate-line" py={1}>
                      <GroupIcon className="rate-icon" />
                      Team: <span>{teamRate.teamName}</span>
                    </Box>
                    <Box className="rate-line" py={1} borderTop={1} borderColor={'#ddd'}>
                      <FunctionsIcon className="rate-icon" />
                      Score: <span>{teamRate.score}</span>
                    </Box>
                    <Box className="rate-line" py={1} borderTop={1} borderColor={'#ddd'}>
                      <CheckIcon className="rate-icon" />
                      Puzzles Solved: <span>{teamRate.solvedPuzzlesCount}</span>
                    </Box>
                    <Box className="rate-line" py={1} borderTop={1} borderColor={'#ddd'}>
                      <ClearIcon className="rate-icon" />
                      Questions Passed: <span>{teamRate.passedPuzzlesCount}</span>
                    </Box>
                    <Box className="rate-line" py={1} borderTop={1} borderColor={'#ddd'}>
                      <VisibilityIcon className="rate-icon" />
                      Hints Used: <span>{teamRate.usedHintsCount}</span>
                    </Box>
                    <Box className="rate-line" py={1} borderTop={1} borderColor={'#ddd'}>
                      <TimerIcon className="rate-icon" />
                      Final Time: 
                      <span>
                        {teamRate.totalTime ? formatFinalTime(teamRate.totalTime) : (gameState == 'FINISHED' ? 'Did not finish' : 'Game in progress')}
                      </span>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            onClick={() => setRatePopupOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TeamsRate;
