import { gql } from '@apollo/client';

export const UPDATE_PUZZLE_STATS = gql`
    subscription updatePuzzleStats($url: String!) {
        updatePuzzleStats(url: $url) {
            score
            currentPuzzleStat {
                puzzle {
                    name
                    file
                    format
                }

                usedHints {
                    text
                }
                availableHintsCount
                lastHintUseDateTime
                mistakes {
                    createdAt
                }
            }
            previousPuzzleStats {
                puzzle {
                    name
                    file
                    answer
                }
                usedHints {
                    text
                }
                isPuzzleSolved
            }
        }
    }
`;
