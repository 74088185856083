import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

export default (
  {
    cancelEventMutation,
    setPopupOpen,
    isPopupOpen,
    eventId,
  },
) => {
  const handleCancel = () => {
    cancelEventMutation({
      variables: {
        eventId,
      },
    });
  };

  return (
    <Dialog
	    disableScrollLock
      open={isPopupOpen}
      onClose={() => setPopupOpen(false)}
    >
      <DialogTitle id="alert-dialog-slide-title">
        Cancel Event
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Are you sure you want to cancel event?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setPopupOpen(false)} color="primary">
          Disagree
        </Button>
        <Button onClick={handleCancel} color="primary">
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};
