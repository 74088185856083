import React from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core';

import GroupIcon from '@material-ui/icons/Group';
import CheckIcon from '@material-ui/icons/Check';
import TimerIcon from '@material-ui/icons/Timer';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FunctionsIcon from '@material-ui/icons/Functions';

import uuid from 'react-uuid';

const headCells = ['Team name', 'Score', 'Solved puzzles', 'Passed puzzles', 'Used hints', 'Total time'];

const TablePart = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell className="monitoring-table-head" key={uuid()} align={'center'}>
          <Box className="monitoring-table-head-content">
            <GroupIcon className="rate-icon" />
            {headCells[0]}
          </Box>
        </TableCell>
        <TableCell className="monitoring-table-head" key={uuid()} align={'center'}>
          <Box className="monitoring-table-head-content">
            <FunctionsIcon className="rate-icon" />
            {headCells[1]}
          </Box>
        </TableCell>
        <TableCell className="monitoring-table-head" key={uuid()} align={'center'}>
          <Box className="monitoring-table-head-content">
            <CheckIcon className="rate-icon" />
            {headCells[2]}
          </Box>
        </TableCell>
        <TableCell className="monitoring-table-head" key={uuid()} align={'center'}>
          <Box className="monitoring-table-head-content">
            <ClearIcon className="rate-icon" />
            {headCells[3]}
          </Box>
        </TableCell>
        <TableCell className="monitoring-table-head" key={uuid()} align={'center'}>
          <Box className="monitoring-table-head-content">
           <VisibilityIcon className="rate-icon" />
            {headCells[4]}
          </Box>
        </TableCell>
        <TableCell className="monitoring-table-head" key={uuid()} align={'center'}>
          <Box className="monitoring-table-head-content">
            <TimerIcon className="rate-icon" />
            {headCells[5]}
          </Box>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TablePart;