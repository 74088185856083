import React, { useState, useEffect } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { Lines as Loader } from 'react-preloaders';

import { GET_COORDINATOR_EVENT } from '../../operations/queries/getCoordinatorEvent';
import { UPDATE_GAME_STATE } from '../../operations/subscriptions/updateGameState';

import { getEventUrlCode } from '../../utils/util';
import { getEventState } from '../../utils/util';

import EventStatusComponent from '../../components/EventStatus';

const EventStatus = () => {

  const eventUrl = getEventUrlCode().replace('/status', '');
  const [gameState, setGameState] = useState('UPCOMING');

  const { loading, data } = useQuery(GET_COORDINATOR_EVENT, {
      variables: { url: eventUrl }
  })

  const {data: newGameState} = useSubscription(UPDATE_GAME_STATE, {
      variables: { url: eventUrl },
      onSubscriptionData: () => {},
  })

  useEffect(() => {
    if (data && data.coordinatorEvent) {
      if (gameState !== data.coordinatorEvent.state) {
        setGameState(data.coordinatorEvent.state);
      }
      if (!data.coordinatorEvent.state || data.coordinatorEvent.state === 'NULL') {
        setGameState(getEventState(data.coordinatorEvent));
      }
    }
  }, [data])
  
  useEffect(() => {
    if (newGameState) {
      setGameState(newGameState.updatedGameState);
    }
  }, [newGameState])

  if (loading) return <Loader background={'#333'} color="#fff" customLoading={loading} />;

  return (
    <div>
      {data.coordinatorEvent !== undefined ? 
        <EventStatusComponent
            eventUrl = {eventUrl}
            event = {data.coordinatorEvent}
            gameState = {gameState}
        />
        : ''
      }
    </div>
  )
}

export default EventStatus;