import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box } from '@material-ui/core';
import Moment from 'react-moment';
import moment from 'moment';
import Notify from '../../utils/notify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default (
  {
    setPopupOpen,
    eventName,
    teamEvent,
    eventStartDatetime,
    isPopupOpen,
  },
) => {
  const handleCopy = () => {
    setPopupOpen(null);
    Notify.copyMail();
  };

  const generateTemplate = () => {
    return `
Event name: ${eventName}
Event date: ${moment(eventStartDatetime).format('DD/MM/YYYY HH:MM')}
Team name: ${teamEvent.team.name}
Team members: ${teamEvent.team.members.filter(member => member.name).map(member => member.name)}
Team notes: ${teamEvent.team.description}
Team URL: ${window.location.origin + '/event/' + teamEvent.url}
`;
  };


  if(!teamEvent) {
    return null;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isPopupOpen}
      onClose={() => setPopupOpen(null)}
	    disableScrollLock
    >
      <DialogTitle id="alert-dialog-slide-title">
        Mail Template
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" component={'div'}>
          <Box className="mail-event-name">
            {eventName}
          </Box>
          <Box className="mail-time time-to-wrap" display="flex" alignItems="center">
            <Box className="time-to">
              <Moment format='MMMM Do YYYY'>
                {eventStartDatetime}
              </Moment>
            </Box>
            <Box className="time-to" ml={3}>
              <Moment format='hh:mm a'>
                {eventStartDatetime}
              </Moment>
            </Box>
          </Box>
          <Box className="mail-team">
            Team {teamEvent.team.name}
          </Box>
          <Box className="mail-title">
            Members:
          </Box>
          {teamEvent.team.members.filter(member => member.name).map((member, index) => (
            <Box className="mail-member" key={index}>
              {`${index + 1}. ${member.name}`}
            </Box>
          ))}
          <Box className="mail-title">
            Notes:
          </Box>
          <Box className="mail-description">
            {teamEvent.description}
          </Box>
          <Box className="mail-title">
            Link:
          </Box>
          <Box className="mail-link">
            {window.location.origin + '/event/' + teamEvent.url}
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setPopupOpen(null)} color="primary">
          Cancel
        </Button>
        <CopyToClipboard text={generateTemplate()}>
          <Button onClick={handleCopy} color="primary">
            Copy
          </Button>
        </CopyToClipboard>
      </DialogActions>
    </Dialog>
  );
};
