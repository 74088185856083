import React from 'react';
import { Box } from '@material-ui/core';
import './style.css';

interface IMobileNavigation {
  memberNames: string[]
  isMenuOpen: boolean
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  setTeamPopupOpen: React.Dispatch<React.SetStateAction<boolean>>
  setCoordinatorPopupOpen: React.Dispatch<React.SetStateAction<boolean>>
  setHowToPlayPopupOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const MobileNavigation: React.FC<IMobileNavigation> = (
  {
    memberNames,
    isMenuOpen,
    setMenuOpen,
    setTeamPopupOpen,
    setCoordinatorPopupOpen,
    setHowToPlayPopupOpen,
  }) => {
  return (
    <div className={`mobile-nav ${isMenuOpen && 'active'}`}>
      <div className="close" onClick={() => {
        setMenuOpen(false);
      }} />

      <ul>
        <ul className="header-nav mob">
          <li>
            {memberNames.length == 0 ? 
              (
                <div></div>
              ) 
            :
              (
                (memberNames.length <= 1 && !memberNames[0]) ?
                  (
                    <div></div>
                  )
                :
                  (
                    <Box onClick={() => {
                      setMenuOpen(false);
                      setTeamPopupOpen(true);
                    }}
                    >
                      Your Team
                    </Box>
                  )
              )
            }
          </li>
          <li>
            <Box onClick={() => {
              setMenuOpen(false);
              setCoordinatorPopupOpen(true);
            }}
            >
              Event Coordinator
            </Box>
          </li>
          <li>
            <Box onClick={() => {
              setMenuOpen(false);
              setHowToPlayPopupOpen(true);
            }}
            >
              How To Play
            </Box>
          </li>
        </ul>
      </ul>
    </div>
  );
};

export default MobileNavigation;
