import React from 'react';
import { Container, Box } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import './style.css';

const ErrorPage: React.FC<{
    primaryText: string, secondaryText: string
}> = ({ primaryText, secondaryText }) => (
  <Container className="error-wrap">
    <Box className="error-icon">
      <ErrorOutlineIcon />
    </Box>

    <Box className="error-title">
      {primaryText}
    </Box>

    <Box className="error-text">
      {secondaryText}
    </Box>
  </Container>
);

export default ErrorPage;
