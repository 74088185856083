import { IEvent, IPuzzleStat } from '../InterfacesFromDB';

export const getRandomColor = (index: number, eventId: number) => {
  let resultColor = null;

  const colorsFromConfig = process.env.REACT_APP_TEAM_COLORS?.split(',');
  
  const rgbToColor = (r:number, g:number, b:number) => {
    return '#' + byteToHex(r) + byteToHex(g) + byteToHex(b);
  }
  
  const byteToHex = (n:number) => {
    var nybHexString = "0123456789ABCDEF";
    return String(nybHexString.substr((n >> 4) & 0x0F,1)) + nybHexString.substr(n & 0x0F,1);
  }
  
  const getColorFromGraduent = () => {
    const center = index*eventId*999%230+50;
    const frequency = 140;
    const phase1 = 0;
    const phase2 = 2;
    const phase3 = 4;
    const width = 127;
    const len = 10;
    
    var gradient = []
  
    for(var i = 0; i < len; ++i) {
        var red = Math.sin(frequency * i + phase1) * width + center;
        var grn = Math.sin(frequency * i + phase2) * width + center;
        var blu = Math.sin(frequency * i + phase3) * width + center;
        gradient.push(rgbToColor(red, grn, blu));
    }

    return gradient[index%len];
  }

  if (typeof colorsFromConfig !== 'undefined') {
    if (colorsFromConfig.length > 0 && index < colorsFromConfig.length) {
      resultColor = colorsFromConfig![index];
    } else {
      resultColor = getColorFromGraduent();
    }
  } else {
    resultColor = getColorFromGraduent();
  }

  return resultColor;
}

export const formatFinalTime = (total: number) => {
  if (total === 0) {
    return '';
  }
  const seconds = total % 60;
  let minutes = Math.floor(total / 60);
  const hours = (minutes >= 60) ? Math.floor(minutes / 60) : 0;
  minutes = (hours > 0) ? (minutes % 60) : minutes;
  return ((hours > 0) ? (((hours < 10) ? '0' : '') + hours) : '') + ((hours > 0) ? ':' : '') + (((minutes < 10) ? '0' : '') + minutes) + ':' + (((seconds < 10) ? '0' : '') + seconds);
};

export const getPlaceEnding = (place: number) => {
  if (place === 1) {
    return 'st';
  }
  if (place === 2) {
    return 'nd';
  }
  if (place === 3) {
    return 'rd';
  }
  return 'th';
};

export const getPlaceColor = (place: number) => {
  if (place === 1) {
    return '#51bfed';
  }
  if (place === 2) {
    return '#8dc9b3';
  }
  if (place === 3) {
    return '#abb5dc';
  }
  return '#333';
};

export const calcAllHints = (stats: IPuzzleStat[]) => {
  return stats.reduce((acc, elem) => {
    return acc + elem.usedHints.length;
  }, 0);
};

export const calcAllSurrenders = (stats: IPuzzleStat[]) => stats.filter(stat => stat.passedAt).length;

// TODO: can be more better
// @ts-ignore
export const getMilliseconds: (date: Date | number | string, convertToNumber?: boolean) => number = (date, convertToNumber = false) => {
  if (!date) {
    return date;
  }
  return new Date(convertToNumber ? Number(date) : date).getTime();
};

export const getEventUrlCode: () => string = () => {
  return window.location.href.substring(window.location.href.indexOf(`/event/`) + 7);
};

export const getAdminUrlCode: () => string = () => {
  return window.location.href.substring(window.location.href.indexOf(`/admin/`) + 7);
};

export const getEventState: (event: IEvent) => string = (event) => {
  if (event.isCanceled) {
    return 'CANCELED';
  }

  const gameStartedAt = new Date(+event.startDatetime).getTime();
  if (gameStartedAt + event.duration < Date.now()) {
    return 'FINISHED';
  }

  if (gameStartedAt > Date.now()) {
    return 'UPCOMING';
  }

  if (!event.rates.length) {
    return 'FINISHED';
  }

  const finishedTeamEvents = event.rates.filter(teamEvent => !!teamEvent.totalTime);
  if (event.rates.length === finishedTeamEvents.length) {
    return 'FINISHED';
  }

  return 'ACTIVE';
}
