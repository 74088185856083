import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import CoordinatorPanel from '../../pages/CoordinatorPanel/CoordinatorPanelPage';
import { getEventUrlCode } from '../../utils/util';

export default ({ children, user, role, ...rest }) => (
  <Route {...rest} render={({ location }) => {
    if (!user) {
      return children;
    }

    if (role === 'admin' && user.role === 'admin') {
      return <Redirect to={{ pathname: '/admin', state: { from: location } }} />;
    }

    if (user.role === 'admin') {
      return <CoordinatorPanel />;
    }

    const url = getEventUrlCode();
    if (url === user.urlIdentity) {
      return <CoordinatorPanel />;
    }
    return children;
  }} />
);
