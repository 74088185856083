import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

export default (
  {
    setPopupOpen,
    isPopupOpen,
    removeTeamMutation,
    id,
  },
) => (
  <Dialog disableScrollLock open={isPopupOpen} onClose={() => {
    setPopupOpen(false);
  }}>
    <DialogTitle id="alert-dialog-slide-title">
      Remove Team
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        Are you sure you want to remove team?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setPopupOpen(false)} color="primary">
        Disagree
      </Button>
      <Button onClick={() => {
        removeTeamMutation({
          variables: {
            id,
          },
        })
      }} color="primary">
        Agree
      </Button>
    </DialogActions>
  </Dialog>
);
