import { gql } from '@apollo/client';

export const EDIT_EVENT = gql`
    mutation editEvent(
        $id: Int!,
        $name: String!,
        $url: String!,
        $customer: String!,
        $startDatetime: String!,
        $duration: Int!,
        $coordinatorName: String!,
        $coordinatorEmail: String!,
        $coordinatorPassword: String!,
        $gameId: Int!,
        $teamsLimit: Int!,
        $description: String!,
        $slackChannelName: String!,
    ) {
        editEvent(
            id: $id,
            name: $name,
            url: $url,
            customer: $customer,
            startDatetime: $startDatetime,
            duration: $duration,
            coordinatorName: $coordinatorName,
            coordinatorEmail: $coordinatorEmail,
            coordinatorPassword: $coordinatorPassword,
            gameId: $gameId,
            teamsLimit: $teamsLimit,
            description: $description,
            slackChannelName: $slackChannelName,
        ) {
            id
        }
    } 
`;
