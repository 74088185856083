import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

interface IHintDialog {
  setPopupOpen: React.Dispatch<React.SetStateAction<boolean>>
  isPopupOpen: boolean
  handleGiveUp: any
  resetForm: () => void
}

const HintDialog: React.FC<IHintDialog> = (
  {
    setPopupOpen,
    isPopupOpen,
    handleGiveUp,
    resetForm,
  },
) => (
  <Dialog
    open={isPopupOpen}
    onClose={() => setPopupOpen(false)}
	  disableScrollLock
  >
    <DialogTitle id="alert-dialog-slide-title">
      Give Up
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        Are you sure you want to know the answer? Your score will be reduced.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setPopupOpen(false)} color="primary">
        No
      </Button>
      <Button 
        onClick={() => {
          setPopupOpen(false);
          handleGiveUp(resetForm);
          }
        } 
        color="primary">
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);


export default HintDialog;
