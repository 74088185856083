import { gql } from '@apollo/client';

export const UPDATE_RATES = gql`
    subscription updateRates($eventId: Int!) {
        updateRates(eventId: $eventId) {
            rates {
                teamName
                score
                solvedPuzzlesCount
                passedPuzzlesCount
                usedHintsCount
                totalTime
            }
        }
    }
`;
