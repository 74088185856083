import React from 'react';
import { Box } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import CheckIcon from '@material-ui/icons/Check';
import TimerIcon from '@material-ui/icons/Timer';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FunctionsIcon from '@material-ui/icons/Functions';
import { formatFinalTime } from '../../../utils/util';
import './style.css';

interface IFinishComponent {
  teamPlace: number
  gameFinalText: string
  teamName: string
  score: number
  solvedPuzzlesCount: number
  passedQuestionsCount: number
  usedHintsCount: number
  totalTime: number
  gameFinalImageUrl: string
  gameState:string
}

const Finish: React.FC<IFinishComponent> = (
  {
    teamPlace,
    gameFinalText,
    teamName,
    score,
    solvedPuzzlesCount,
    passedQuestionsCount,
    usedHintsCount,
    totalTime,
    gameFinalImageUrl,
    gameState,
  },
) => {

  return (
    <Box>
      <Box className="finish-block" width="100%" border={1} borderColor="#ddd">
        <Box className="finish-img">
          <img src={`${process.env.REACT_APP_IMAGE_PATH}img/${gameFinalImageUrl}`} alt="game" />
        </Box>

        <Box className="final-wrap">
          <Box className="final-text" p={3}>
            <Box className="end-title">
              Finish
            </Box>
            <Box className="end-text" dangerouslySetInnerHTML={{__html: gameFinalText}}/>
          </Box>

          <Box className="final-info" px={3} py={1}>
            <Box className="rate-line" py={2}>
              <GroupIcon className="rate-icon" />
              Team: <span>{teamName}</span>
            </Box>
            <Box className="rate-line" py={1} borderTop={1} borderColor={'#ddd'}>
              <FunctionsIcon className="rate-icon" />
              Score: <span>{score}</span>
            </Box>
            <Box className="rate-line" py={2} borderTop={1} borderColor={'#ddd'}>
              <CheckIcon className="rate-icon" />
              Puzzles solved: <span>{solvedPuzzlesCount}</span>
            </Box>
            <Box className="rate-line" py={2} borderTop={1} borderColor={'#ddd'}>
              <ClearIcon className="rate-icon" />
              Questions Passed: <span>{passedQuestionsCount}</span>
            </Box>
            <Box className="rate-line" py={2} borderTop={1} borderColor={'#ddd'}>
              <VisibilityIcon className="rate-icon" />
              Hints used: <span>{usedHintsCount}</span>
            </Box>
            <Box className="rate-line" py={2} borderTop={1} borderColor={"#ddd"}>
              <TimerIcon className="rate-icon"/>
              Final time:
              <span>
                {formatFinalTime(totalTime)}
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Finish;
