import { gql } from '@apollo/client';

//Queries
export const LOGIN_USER = gql`
    query loginUser($urlIdentity:String!, $email:String!, $password:String!, $role:String!) {
        loginUser(urlIdentity:$urlIdentity, email:$email, password:$password, role:$role) {
            token
        }
    }
`;
