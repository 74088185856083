import React from 'react';
import { Avatar, Typography, Container, Box } from '@material-ui/core';
import { Formik } from 'formik';
import FormComponent from './form';
import { validationSchema } from './validation';
import { parseError } from '../../utils/parseGraphqlError';
import { LOGIN_USER } from './graphql';
import './style.css';
import { getEventUrlCode } from '../../utils/util';
import apolloClient from '../../utils/apolloClient';

const CoordinatorForm = () => (
  <Container component="main" maxWidth="xs">
    <Box display="flex" flexDirection="column" alignItems="center" pt={10}>
      <Avatar className="avatar" />
      <Typography component="h1" variant="h5">
        Coordinator
      </Typography>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={({ email, password }, { setFieldError }) => {
          apolloClient.query({
            query: LOGIN_USER,
            variables: {
              urlIdentity: getEventUrlCode(),
              password,
              email,
              role: 'coordinator',
            },
          })
            .then(({ data }) => {
              if (!data.loginUser) {
                return;
              }
              const token = data.loginUser.token;
              localStorage.setItem('token', token);
              window.location.reload();
            })
            .catch(error => {
              const errorObject = parseError(error);
              setFieldError(errorObject.field, errorObject.text);
            });
        }}
      >
        {props => <FormComponent {...props} />}
      </Formik>
    </Box>
  </Container>
);


export default CoordinatorForm;
