import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import uuid from 'react-uuid';

export default function TablePart({ headCells }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={uuid()} align={'center'}>
            {headCell}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}