import * as Yup from 'yup';

export const validationSchema = Yup.object({
  teamName: Yup.string()
    .required('Team name is required'),
  members: Yup
    .array()
    .of(Yup.object().shape({
      id: Yup.number(),
      name: Yup.string(),
    })),
  notes: Yup.string(),
});
