import React from 'react';
import AdminForm from '../../components/AdminForm/'

const AdminLogin = () => {
    return (
        <div>
            <AdminForm/>
        </div>
    );
}

export default AdminLogin;
