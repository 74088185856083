import React from 'react';
import {Box, Typography} from '@material-ui/core';
import './style.css';
import {Image} from "@material-ui/icons";
import ArenaLogo from '../../../img/teambuildingarenalogo_02.png';

const Footer = React.memo(() => {
  return (
    <Box style={{marginTop:'auto'}} display="flex" justifyContent="center" p={2} className="footer-block">
        <img src={ArenaLogo} className="footer-logo" alt="Team Building Arena logo" />
        <Typography className="footer-text">© 2021 <a className="footer-link" href="https://www.teambuildingarena.com" target="_blank" >Team Building Arena</a>.  All rights reserved.</Typography>
    </Box>
  );
});

export default Footer;
