import React from 'react';
import CoordinatorHeaderComponent from '../components/CoordinatorPanel/coordinatorHeader/CoordinatorHeaderComponent';
import { IEvent } from '../InterfacesFromDB';
import { TEventRefetch } from '../InterfacesApollo';

const CoordinatorHeader: React.FC<{
  gameState: string,
  event: IEvent,
  eventRefetch: TEventRefetch
}> = ({ gameState, event, eventRefetch }) => (
  <CoordinatorHeaderComponent
    event={event}
    gameState={gameState}
    eventRefetch={eventRefetch}
  />
);

export default CoordinatorHeader;

