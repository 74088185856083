import { gql } from '@apollo/client';

export const ADD_TEAM = gql`
    mutation addTeam(
        $name: String!, 
        $notes: String!, 
        $members: [String]! 
        $url: String!
        $eventId: Int!
        $color: String!
    ) {
        addTeam(
            name: $name, 
            notes: $notes, 
            members: $members, 
            url: $url
            eventId: $eventId
            color: $color
        ) {
            id
        }
    }
`;
