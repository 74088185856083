import React, { useState } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { Formik } from 'formik';
import Moment from 'react-moment';
import moment from 'moment';
import Countdown, { CountdownRendererFn } from 'react-countdown';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import './style.css';
import { IEvent } from '../../../InterfacesFromDB';
import { TEventRefetch } from '../../../InterfacesApollo';
import { useMutation } from '@apollo/client';
import { RESCHEDULE } from '../../../operations/mutations/reschedule';
import StartNowDialog from '../startNowDialog';

const Component: React.FC<{
  gameState: string,
  event: IEvent,
  eventRefetch: TEventRefetch
}> = ({ gameState, event, eventRefetch }) => {

  const eventStartsAt = new Date(+event.startDatetime).toUTCString();
  const eventEndsAt = moment(eventStartsAt)
    .add(event.duration / 1000, 's')
    .toDate();

  const [isPickerOpened, setPickerOpened] = useState(false);
  const [isStartNowPopupOpen, setStartNowPopupOpen] = useState(false);
  const [error, setError] = useState('');

  const [reschedule] = useMutation(RESCHEDULE, {
    onCompleted: () => {
      setError('');
      eventRefetch().catch(e => console.error(e));
    },
  });

  const renderEndCountdown: CountdownRendererFn = ({ hours, minutes, seconds }) => {
    return (
      <span className="game-time-value">
        <span>{hours}</span> {(hours > 1) ? 'Hours' : 'Hour'}
        <span>{minutes}</span> {(minutes > 1) ? 'Minutes' : 'Minute'}
        <span>{seconds}</span> Seconds
      </span>
    );
  };

  const renderStartCountdown: CountdownRendererFn = ({ days, hours, minutes, seconds }) => {
    return (
      <span className="game-countdown2">
        <span>{days}</span> Days
        <span>{hours}</span> Hours
        <span>{minutes}</span> Minutes
        <span>{seconds}</span> Seconds
      </span>
    );
  };

  return (
    <Box
      className="game-all-info coord-head"
      mt={3}
      pb={1}
      display="flex"
      justifyContent="space-between"
      width="100%"
    >
      <Box className="event-info">
        <Typography className="event-name" variant="h4">
          {event.name}
        </Typography>
        <Box className="team-info">
          <Box className="time-to-wrap">
            <Box className="time-to">
              <Moment format='MMMM Do YYYY'>
                {eventStartsAt}
              </Moment>
            </Box>
            <Box className="time-to" ml={3}>
              <Moment format='hh:mm a'>
                {eventStartsAt}
              </Moment>
            </Box>
          </Box>

          <Formik
            initialValues={
              {
                time: new Date(event.startDatetime).getTime(),
              }
            }
            onSubmit={({ time }, { setFieldValue }) => {
              const newEventDate = new Date(+time).getTime();
              const currentDate = Date.now();

              if (newEventDate < currentDate) {
                setFieldValue('time', new Date(+event.startDatetime).getTime());
                return setError('Event should be in future');
              }

              if ((newEventDate - (Number(process.env.REACT_APP_MIN_TIME_BEFORE_GAME) || 15) * 60 * 1000) < currentDate) {
                setFieldValue('time', new Date(+event.startDatetime).getTime());
                return setError(`Cannot reschedule the game to less than ${process.env.REACT_APP_MIN_TIME_BEFORE_GAME} minutes from now`);
              }
              reschedule({
                variables: {
                  startDatetime: time,
                  id: event.id,
                }
              }).catch(e => console.error(e));
            }}
          >
            {({ values, errors, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Box className="datetime-wrap datetime-wrap-hidden">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      className="timepicker"
                      open={isPickerOpened}
                      onClose={() => setPickerOpened(false)}
                      value={values.time}
                      onChange={(values) => {
                        setFieldValue('time', values);
                        handleSubmit();
                      }}
                      error={!!errors.time}
                      helperText={errors.time}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              </form>
            )}
          </Formik>

          <Box className="picker-wrap">
            <Button
              disabled={gameState !== 'UPCOMING'}
              type="submit"
              onClick={() => setPickerOpened(true)}
              fullWidth
              variant="contained"
              color="primary"
              size="small"
              className='resch-btn'
            >
              Reschedule
            </Button>
            <Box className="picker-error">
              {error}
            </Box>
          </Box>

          <Box className="start-wrap">
            <Button
              disabled={gameState !== 'UPCOMING'}
              type="submit"
              onClick={() => setStartNowPopupOpen(true)}
              fullWidth
              variant="contained"
              color="primary"
              size="small"
              className='start-btn'
            >
              Start now
            </Button>
          </Box>

          <Box className="teams-info">
            {event.teamEvents.length} Teams
          </Box>
        </Box>
      </Box>

      <Box className="game-time-info">
        {gameState === 'UPCOMING' && (
          <Box className="game-time" display="flex" flexDirection="column" alignItems="center">
            <Box className="game-time-text">Game starts in </Box>
            <Countdown date={eventStartsAt} renderer={renderStartCountdown} />
          </Box>
        )}
        {gameState === 'ACTIVE' && (
          <Box className="game-time" display="flex" flexDirection="column" alignItems="center">
            <Box className="game-time-text">Time Left:</Box>
            <Countdown date={eventEndsAt} renderer={renderEndCountdown} />
          </Box>
        )}
      </Box>

      <StartNowDialog
          isPopupOpen={isStartNowPopupOpen}
          setPopupOpen={setStartNowPopupOpen}
          reschedule={reschedule}
          eventId={event.id}
      />

    </Box>
  );
};

export default Component;
