import {toast} from 'react-toastify'

 const Notify = {
    addEvent: () => {
        toast.success("Event was added", {
            autoClose: 1500,
            containerId: 'one',
        })
    },
    editEvent: () => {
        toast.success("Event was edited", {
            autoClose: 1500,
            containerId: 'one',
        })
    },
    cancelEvent: () => {
        toast.success("Event was canceled", {
            autoClose: 1500,
            containerId: 'one',
        })
    },
    addTeam: () => {
        toast.success("Team was added", {
            autoClose: 1500,
            containerId: 'one',
        })
    },
    editTeam: () => {
        toast.success("Team was edited", {
            autoClose: 1500,
            containerId: 'one',
        })
    },
    removeTeam: () => {
        toast.success("Team was removed", {
            autoClose: 1500,
            containerId: 'one',
        })
    },
    copyMail: () => {
        toast.success("Template coppied", {
            autoClose: 1500,
            containerId: 'one',
        })
    },
    notifyCoord: () => {
        toast.success("Logs was sent", {
            autoClose: 1500,
            containerId: 'one',
        })
    },
}

export default Notify;