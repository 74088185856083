import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@material-ui/core';
import './style.css';
import Fireworks from '../../../utils/confettiAnimation';
import Finish from '../Finish/FinishComponent';
import Puzzle from '../Puzzle/PuzzleComponent';
import { IPuzzleStat, IRate } from '../../../InterfacesFromDB';
import PreviousPuzzle from '../Puzzle/PreviousPuzzleComponent';

interface IPuzzles {
  gameState: string
  teamPlace: number
  gameFinalText: string
  currentTeamRate: IRate | undefined
  gameFinalImageUrl: string
  startEventDateTime: Date
  eventId: number
  previousPuzzles: IPuzzleStat[]
  currentPuzzle: IPuzzleStat
  quizzesCount: number
  score: number
  requestToRefetch: () => void
  url: string
}

const Puzzles: React.FC<IPuzzles> = (
  {
    gameState,
    teamPlace,
    gameFinalText,
    currentTeamRate,
    gameFinalImageUrl,
    startEventDateTime,
    eventId,
    previousPuzzles,
    currentPuzzle,
    quizzesCount,
    score,
    requestToRefetch,
    url,
  },
) => {
  const isInitialMount = useRef(true);
  const finishRef = React.createRef<HTMLDivElement>();
  const puzzleRef = React.createRef<HTMLDivElement>();
  const [previousPuzzlesCount, setPreviousPuzzlesCount] = useState(previousPuzzles.length);
  const [startScrollingToPuzzle, setStartScrollingToPuzzle] = useState(false);
  const [startScrollingToFinish, setStartScrollingToFinish] = useState(false);
  const [runFirework, setRunFirework] = useState(false);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } 
    else {
      if (quizzesCount == previousPuzzles.length) {
        setStartScrollingToFinish(true);
      }
    }
  }, [previousPuzzles.length]);

  useEffect(() => {
    let updatedPreviousPuzzlesCount = previousPuzzles.length;

    if ((previousPuzzlesCount !== updatedPreviousPuzzlesCount) && currentPuzzle) {
      setPreviousPuzzlesCount(previousPuzzles.length);
      setStartScrollingToPuzzle(true);
    }
  }, [previousPuzzles.length]);

  useEffect(() => {
    let PixelsFromTopToScroll = 0;
    
    if (puzzleRef.current) {
      PixelsFromTopToScroll = puzzleRef?.current?.getBoundingClientRect().top + window.pageYOffset;
    }

    if (PixelsFromTopToScroll !== 0 && startScrollingToPuzzle) {
      setTimeout(function(){
        window.scrollTo({
          top: PixelsFromTopToScroll,
          behavior: "smooth",
        });
        setStartScrollingToPuzzle(false);
      }, 1000);
    }
  }, [startScrollingToPuzzle]);
  
  useEffect(() => {
    let PixelsFromTopToScroll = 0;
    
    if (finishRef.current) {
      PixelsFromTopToScroll = finishRef?.current?.getBoundingClientRect().top + window.pageYOffset;
    }
    if (PixelsFromTopToScroll !== 0 && startScrollingToFinish) {
      setTimeout(function(){
        window.scrollTo({
          top: PixelsFromTopToScroll,
          behavior: "smooth",
        });
        setStartScrollingToFinish(false);
      }, 1000);
      setTimeout(function(){
        setRunFirework(true);
      }, 1500)
      setTimeout(function(){
        setRunFirework(false);
      }, 2000);
    }
  }, [startScrollingToFinish]);

  return (
    <Box>
      <Box className="all-puzzles-wrap">
        {previousPuzzles.map((puzzleStat, index) => (
          <PreviousPuzzle
            key={index}
            answerText={puzzleStat.puzzle.answer}
            usedHints={puzzleStat.usedHints.map(hint => hint.text)}
            isPuzzleSolved={puzzleStat.isPuzzleSolved}
            quizNumber={index + 1}
            quizName={puzzleStat.puzzle.name}
            eventId={eventId}
            partPDF={puzzleStat.puzzle.file}
            gameState={gameState}
          />
        ))}
        <div ref={puzzleRef}>
          {gameState === 'ACTIVE' && currentPuzzle && (
            <Puzzle
              usedHints={currentPuzzle.usedHints.map(hint => hint.text)}
              availableHintsCount={currentPuzzle.availableHintsCount}
              startEventDateTime={startEventDateTime}
              lastHintUseDateTime={currentPuzzle.lastHintUseDateTime}
              quizNumber={previousPuzzles.length + 1}
              quizName={currentPuzzle.puzzle.name}
              eventId={eventId}
              partPDF={currentPuzzle.puzzle.file}
              mistakes={currentPuzzle.mistakes}
              requestToRefetch={requestToRefetch}
              url={url}
            />
          )}
        </div>
      </Box>
      <div ref={finishRef}>
        {(gameState === 'FINISHED' || (gameState === 'ACTIVE' && !currentPuzzle)) && (
          <Finish
            teamPlace={teamPlace}
            gameFinalText={gameFinalText}
            teamName={currentTeamRate ? currentTeamRate.teamName : ''}
            solvedPuzzlesCount={currentTeamRate ? currentTeamRate.solvedPuzzlesCount : 0}
            passedQuestionsCount={currentTeamRate ? currentTeamRate.passedPuzzlesCount : 0}
            usedHintsCount={currentTeamRate ? currentTeamRate.usedHintsCount : 0}
            totalTime={currentTeamRate ? currentTeamRate.totalTime : 0}
            score={score ? score : 0}
            gameFinalImageUrl={gameFinalImageUrl}
            gameState={gameState}
          />
        )}
        <Box></Box>
      </div>
      
      <Fireworks runFirework={runFirework}>   
      </Fireworks>
    </Box>
  );
};

export default Puzzles;
