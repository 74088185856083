import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import { createMuiTheme, CssBaseline, MuiThemeProvider } from '@material-ui/core';
import MainNavigation from './navigation/MainNavigation';
import client from './utils/apolloClient';
import {ApolloProvider} from "@apollo/client";

const theme = createMuiTheme({  
    palette: {primary: {main: "#556cd6"}}
});


const App = () => {
    return (
        <ApolloProvider client={client}>
            <Router>
                <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <MainNavigation />
                </MuiThemeProvider>
            </Router>
        </ApolloProvider>
    );
}

export default App;