import React, { useState, useEffect } from 'react';
import TeamLoginComponent from '../../components/TeamLogin';
import { getEventUrlCode } from '../../utils/util';
import { useQuery, useSubscription } from '@apollo/client';
import { GET_COORDINATOR_EVENT } from '../../operations/queries/getCoordinatorEvent';
import { UPDATE_GAME_STATE } from '../../operations/subscriptions/updateGameState';
import { Lines as Loader } from 'react-preloaders';
import { getEventState } from '../../utils/util';

const TeamLogin = () =>  {
    const eventUrl = getEventUrlCode().replace('/login', '');
    const [gameState, setGameState] = useState('UPCOMING');

    const { loading, data, error} = useQuery(GET_COORDINATOR_EVENT, {
        variables: { url: eventUrl }
    })

    const {data: newGameState} = useSubscription(UPDATE_GAME_STATE, {
        variables: { url: eventUrl },
        onSubscriptionData: () => {},
    })

    useEffect(() => {
      if (data && data.currentTeamEvent) {
        if (gameState !== data.currentTeamEvent.event.state) {
          setGameState(data.currentTeamEvent.event.state);
        }
        if (!data.currentTeamEvent.event.state || data.currentTeamEvent.event.state === 'NULL') {
          setGameState(getEventState(data.currentTeamEvent.event));
        }
      }
    }, [data])
    
    useEffect(() => {
      if (newGameState) {
        setGameState(newGameState.updatedGameState);
      }
    }, [newGameState])

    if (loading) return <Loader background={'#333'} color="#fff" customLoading={loading} />;

    return (
        <div>
            <TeamLoginComponent
                gameState = {gameState}
                eventUrl = {eventUrl}
                eventName = {data.coordinatorEvent.name}
                startEventDateTime = {new Date(+data.coordinatorEvent.startDatetime)}
                endEventDateTime = {new Date(+data.coordinatorEvent.startDatetime + data.coordinatorEvent.duration)}
                teamsCount = {data.coordinatorEvent.rates.length}
                waitingImageUrl = {data.coordinatorEvent.game.waitingImage}
                topImageUrl = {data.coordinatorEvent.game.topImage}
                gameBGImage = {data.coordinatorEvent.game.bgImage}
                urlsForTeamEvents={data.coordinatorEvent.teamEvents.map((x) => { return x.url })}
            />
        </div>
    )
}

export default TeamLogin;