import React, { useEffect, useState } from 'react';
import { Box, Container, TextField, Button, Typography } from '@material-ui/core';
import "./style.css"
import FlipCountdown from '@rumess/react-flip-countdown';
import clockIcon from '../../img/clock_sm.png'
import teamIcon from '../../img/team_sm.png';
import Footer from '../Game/Footer';
import Moment from 'react-moment';
import Countdown, { CountdownRendererFn } from 'react-countdown';

interface ITeamLogin {
    gameState: string
    eventUrl: string
    eventName: string
    startEventDateTime: Date
    endEventDateTime: Date
    teamsCount: number
    waitingText: string
    waitingImageUrl: string
    topImageUrl: string
    gameBGImage: string
    urlsForTeamEvents: string[]
}

const TeamLoginComponent: React.FC<ITeamLogin> = (
    {
        gameState,
        eventUrl,
        eventName,
        startEventDateTime,
        endEventDateTime,
        teamsCount,
        waitingImageUrl,
        topImageUrl,
        gameBGImage,
        urlsForTeamEvents,
    }
) => {

    const [teamCode, setTeamCode] = useState('');
    const [showError, setShowError] = useState(false);
    const [countDownTo, setCountDownTo] = useState('00:00:00')
    const [eventStarted, setEventStarted] = useState(false);
    let startAt = new Date(+startEventDateTime);

    useEffect(() => {
        setCountDownTo(startAt.toString());
        const interval = setInterval(function () {
          if (startAt.getTime() > Date.now()) {
          }
          else {
            setEventStarted(true);
            clearInterval(interval);
          }
        }, 500)
    }, [startAt])
    
    const renderCountdown: CountdownRendererFn = ({ hours, minutes, seconds }) => (
        <span className="game-time-value">
            <span>{(hours < 10) ? '0' : ''}{hours}:{(minutes < 10) ? '0' : ''}{minutes}:{(seconds < 10) ? '0' : ''}{seconds}</span>
        </span>
    );

    const checkCode = () => {
        const urlToCheck = eventUrl + '/' + teamCode;
        let isCodeCorrect = false;
        for (const url of urlsForTeamEvents) {
            if (urlToCheck.toUpperCase() === url.toUpperCase()) {
                isCodeCorrect = true;
                window.location.href = `${teamCode.toUpperCase()}`;
            }
        }
        if (!isCodeCorrect) {
            setShowError(true)
        }
    }

    return (
      <Box>
        <Container>
          <Box className='top-game-img-wrapper container container-big'>
            <Box className='top-game-img'>
              <Box className='top-image'>
                <img src={`${process.env.REACT_APP_IMAGE_PATH}img/${topImageUrl}`} alt='game' />
              </Box>
            </Box>
          </Box>
          <div className='container container-big'>
            <Box
              className='game-all-info'
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              width='100%'
            >
              <Box className='header-background-2'>
                <img src={`${process.env.REACT_APP_IMAGE_PATH}img/topBG_image2.jpg`} alt='' />
              </Box>

              <Box className='event-info game-window'>
                <Typography className='event-name' variant='h4'>
                  {eventName}
                </Typography>
              </Box>

              <Box className='game-time-info'>
                {gameState === 'UPCOMING' && (
                  <Box className='time-to-wrap' display='flex' alignItems='center'>
                    <Box className='time-to'>
                      <Moment format='MMMM Do YYYY'>{startAt}</Moment>
                    </Box>
                    <Box className='time-to' ml={3}>
                      <Moment format='hh:mm a'>{startAt}</Moment>
                    </Box>
                  </Box>
                )}
                {gameState === 'ACTIVE' && (
                  <Box
                    className='game-time'
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box className='game-time-text'>TIME LEFT</Box>
                    <Countdown date={endEventDateTime} renderer={renderCountdown} />
                  </Box>
                )}
              </Box>
              <Box className='empty'></Box>
            </Box>
            <Box
              className='waiting-block'
              display='flex'
              alignItems='center'
              width='100%'
              flexDirection='column'
            >
              <Box
                className='top-header-nav-block'
                display='flex'
                justifyContent='space-between'
                width='100%'
              ></Box>
              <Box className='header-waiting-background-1'>
                <img src={`${process.env.REACT_APP_IMAGE_PATH}img/topBG_image1.jpg`} alt='' />
              </Box>
              <Box className='background-image'>
                {gameBGImage ? (
                  <img src={`${process.env.REACT_APP_IMAGE_PATH}img/${gameBGImage}`} alt='' />
                ) : null}
              </Box>
              <Container maxWidth='md' style={{ padding: 0 }}>
                <Box className='team-login-wrapper'>
                  <Box className='waiting-block-header' width='100%' border={1} borderColor='#ddd'>
                    <Box className='start-in'>
                      <span className='start-in-text'>
                        {startAt.getTime() > Date.now()
                          ? 'Game Starts in'
                          : (gameState === 'FINISHED'
                          ? 'Game is over'
                          : 'Game started')}
                      </span>
                      {startAt.getTime() > Date.now() && (
                        <FlipCountdown
                          dayTitle='Days'
                          hourTitle='Hours'
                          minuteTitle='Minutes'
                          secondTitle='Seconds'
                          hideYear
                          hideMonth
                          size='medium'
                          titlePosition='bottom'
                          endAt={countDownTo}
                        />
                      )}
                    </Box>
                    <Box className='game-waiting-image'>
                      <img
                        src={`${process.env.REACT_APP_IMAGE_PATH}img/${waitingImageUrl}`}
                        alt='Game image'
                      />
                    </Box>
                  </Box>
                  <Box className='game-waiting-wrapper'>
                    <Box className='game-waiting-content'>
                      <div id='flipdown' className='flipdown'></div>
                      <Box className='game-waiting-content-header'>
                        <Box className='waiting-content-header-time'>
                          <img
                            className='waiting-content-header-icon'
                            src={clockIcon}
                            alt='hourglass'
                          />
                          <span>
                            {(
                              Math.abs(endEventDateTime.getTime() - startEventDateTime.getTime()) /
                              60000
                            ).toString()}{' '}
                            minutes
                          </span>
                        </Box>
                        <Box className='waiting-content-header-teams'>
                          <img className='waiting-content-header-icon' src={teamIcon} alt='teams' />
                          <span>{teamsCount} Teams Playing</span>
                        </Box>
                      </Box>
                      <Box className='team-login-content-body'>
                        <span className='team-login-title'>
                          To access your team's page, enter your Team Code.
                        </span>
                        <Box className='team-login-enter-code-block'>
                          <TextField
                            error={showError}
                            id='team-login-enter-code-input'
                            label='Team Code'
                            variant='outlined'
                            classes={{
                              root: 'team-login-enter-code-input',
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                checkCode();
                              }
                            }}
                            onChange={(event) => {
                              setShowError(false);
                              setTeamCode(event.target.value);
                            }}
                            helperText={showError ? 'Incorrect code' : ''}
                          />
                          <Button
                            size='medium'
                            variant='contained'
                            color='primary'
                            className='check-code-btn'
                            onClick={() => checkCode()}
                          >
                            Enter
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Box>
          </div>
        </Container>
        <Footer />
      </Box>
    );
}

export default TeamLoginComponent;