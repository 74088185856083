import * as Yup from 'yup';

export const validationSchema = Yup.object({
  email: Yup.string()
    .email('Wrong email format')
    .min(4, 'Minimum length is 4 symbols')
    .required('Login is required'),
  password: Yup.string()
    .min(4, 'Minimum length is 4 symbols')
    .required('Password is required'),
});

