import React, { useEffect, useState } from 'react';
import {
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';

import uuid from 'react-uuid';

export default function TablePart({ rates, condition, order }) {
  const [sortedRates, setSortedRates] = useState([]);

  const sortFn = (a, b, condition, order) => {
    if (condition === 'score') {
      if (order === 'asc') {
        return a.score - b.score; 
      } else if (order === 'desc') {
        return b.score - a.score
      }
    } else if (condition === 'solvedPuzzles') {
      if (order === 'asc') {
        return a.solvedPuzzlesCount - b.solvedPuzzlesCount
      } else if (order === 'desc') {
        return b.solvedPuzzlesCount - a.solvedPuzzlesCount
      }
    }
  }

  useEffect(() => {
    setSortedRates([].concat(rates).sort((a, b) => sortFn(a, b, condition, order)));
  }, [condition, order, rates])

  return (
    <TableBody>
      {sortedRates.map((rate, i) => {
        return (
          <TableRow className="monitoring-rates-row" hover key={uuid()}>
            <TableCell align="center">
              {rate.teamName}
            </TableCell>
            <TableCell align="center">
              {rate.score}
            </TableCell>
            <TableCell align="center">
              {rate.solvedPuzzlesCount}
            </TableCell>
            <TableCell align="center">
              {rate.passedPuzzlesCount}
            </TableCell>
            <TableCell align="center">
              {rate.usedHintsCount}
            </TableCell>
            <TableCell align="center">
              {rate.totalTime}
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  );
}