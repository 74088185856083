import React, { useState } from 'react';
import apolloClient from '../../utils/apolloClient';
import { ADD_TEAM } from '../../operations/mutations/addTeam';
import Notify from '../../utils/notify';
import { getRandomColor } from '../../utils/util';
import cryptoRandomString from 'crypto-random-string';
import { 
    Box, 
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog
 } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { CSVReader } from 'react-papaparse';

export default (
    {
        isPopupOpen,
        setPopupOpen,
        teamEvents, 
        maxPlayersInTeam,
        teamsLimit,
        eventId,
        eventRefetch,
        eventUrl,
    },
) => {
    const [errors, setErrors] = useState([])
    const [duplicateTeamNameAmongExistingTeams, setDuplicateTeamNameAmongExistingTeams] = useState([])
    const [teamsFromFile, setTeamsFromFile] = useState([])
    const [checkCompleted, setCheckCompleted] = useState(false);
    
    const errorNotAllowedCharactersMessage = `Team name must be alphanumeric.`
    const errorLengthMessage = `Team name must be less than 20 characters.`
    const errorCountOfMembersMessage = `Count of team members must be less than ${maxPlayersInTeam ?? process.env.REACT_APP_TEAM_MEMBERS_COUNT}.`
    const errorDuplicateTeamNamesInFileMessage = `Each team name must be unique.`
    const errorTooMuchTeamsInFileMessage = `You can upload only ${teamsLimit - teamEvents.length} teams.`
    const errorUnexpectedErrorInUploadingTheFileMessage = 'Unexpected error while uploading the file. PLease try again.'
    const errorEmptyFileMessage = 'File is empty.'

    const addTeam = (team, teamIndex) => {
        apolloClient.mutate({
            mutation: ADD_TEAM,
            variables: {
              name: team.name,
              notes: '',
              eventId: eventId,
              url: `${eventUrl}/${cryptoRandomString({ length: 5 }).toUpperCase()}`,
              members: team.members,
              color: getRandomColor(teamIndex, eventId),
            },
          }).then(() => {
            eventRefetch();
            Notify.addTeam();
          }).catch(e => {
              
          });
    }

    const addError = (teamName, errorMessage) => {
        let error = {};
        error.teamName = teamName;
        error.message = errorMessage;
        errors.push(error)
    }

    const checkAlphaNumeric = (teamName) => {
        if (!teamName.match(/^[0-9a-zA-Z ]+$/)) {
            addError(teamName, errorNotAllowedCharactersMessage)
        }
    }

    const checkLength = (teamName) => {
        if (teamName.length > 20) {
            addError(teamName, errorLengthMessage);
        }
    }

    const checkUnique = (teamEvents, teams) => {
        let teamNames = [];
        for (const team of teams) {
            teamNames.push(team.name)
        }

        let isDuplicateTeamNameInFile = teamNames.some((element, index) => {return teamNames.indexOf(element) !== index});
        if (isDuplicateTeamNameInFile) {
            addError('', errorDuplicateTeamNamesInFileMessage);
        }

        let existingTeamNames = [];
        for (const teamEvent of teamEvents) {
            existingTeamNames.push(teamEvent.team.name);
        };

        let notUniqueNames = existingTeamNames.filter((value) => teamNames.includes(value));
        if (notUniqueNames.length !== 0) {
            duplicateTeamNameAmongExistingTeams.push(...notUniqueNames);
        }

    }

    const checkCountOfMembers = (team) => {
        if (team.members.length > (maxPlayersInTeam ?? process.env.REACT_APP_TEAM_MEMBERS_COUNT)) {
            addError(team.name, errorCountOfMembersMessage);
        }
    }

    const checkTeamsLimit = () => {
        if (teamsFromFile.length > (teamsLimit - teamEvents.length)) {
            addError('', errorTooMuchTeamsInFileMessage);
        }
    }

    const checkTeamNames = (teams) => {
        checkUnique(teamEvents, teams);
        checkTeamsLimit();

        teams.forEach(team => {
            checkAlphaNumeric(team.name);
            checkLength(team.name);
            checkCountOfMembers(team);
        })

        setCheckCompleted(true);
    }

    const handleOnDrop = (data) => {
        if (data.length === 0) {
            addError('', errorEmptyFileMessage);
        }
        else {
            data.forEach(team => {
                let teamName = '';
                let teamMembers = [];
                let teamWithMembers = {};
                team.data.forEach((element, index) => {
                    if (element !== '') {
                        if (index === 0) {
                            if (element.includes('|')) {
                                teamName = element.split('|')[0];
                                teamMembers.push(element.split('|')[1]);
                            }
                            else {
                                teamName = element;
                                teamMembers.push('');
                            }
                        } else {
                            teamMembers.push(element);
                        }
                    }
                });
                if (teamName !== '') {
                    teamWithMembers.name = teamName;
                    teamWithMembers.members = teamMembers;
                    teamsFromFile.push(teamWithMembers);
                } 
            });
        }
        
        checkTeamNames(teamsFromFile);
    };

    const handleOnError = (err, file, inputElem, reason) => {
        addError('', errorUnexpectedErrorInUploadingTheFileMessage)
    };

    const handleOnRemoveFile = () => {
        resetUpload();
    };

    const resetUpload = () => {
        setErrors([]);
        setDuplicateTeamNameAmongExistingTeams([]);
        setTeamsFromFile([]);
        setCheckCompleted(false);
    }

    const UploadTeams = async () => {
        if (errors.length === 0) {
            let teamIndex = teamEvents.length;
            for (const team of teamsFromFile) {
                if (!duplicateTeamNameAmongExistingTeams.includes(team.name)) {
                    addTeam(team, teamIndex);
                    await new Promise(r => setTimeout(r, 500));
                }
                teamIndex++;
            }
        }
        resetUpload();
        setPopupOpen(false);
    }

  return (
    <Box>
      <Dialog
        disableBackdropClick={true}
        open={isPopupOpen}
        onClose={() => setPopupOpen(false)}
        aria-labelledby='form-dialog-title'
        maxWidth='m'
        disableScrollLock
      >
        <DialogTitle id='form-dialog-title'>Upload Teams</DialogTitle>
        <DialogContent className='upload-team-body'>
            <Box className="upload-team-input">
                <CSVReader
                    onDrop={handleOnDrop}
                    onError={handleOnError}
                    addRemoveButton
                    onRemoveFile={handleOnRemoveFile}
                    accept='text/csv, .csv, .xsv, application/vnd.ms-excel'
                >
                    <span>Drop XSV file here or click to upload.</span>
                </CSVReader>
            </Box>

            {checkCompleted && errors.length !== 0 ? (
                <div className="upload-errors-list">
                    <span className="upload-errors-title">To continue uploading you have to fix the following errors</span>
                    {errors.map((error, index) => (
                        <div className="upload-errors-item" key={index}>
                            <ErrorOutlineIcon/>
                            <span className="upload-errors-item-name">{error.teamName ? error.teamName : ''}</span>
                            {error.teamName !== '' && <span className="upload-errors-item-separator">-</span>}
                            <span className="upload-errors-item-content">{error.message}</span>
                        </div>
                    ))}
                </div>
            ) : <div></div>}
            {duplicateTeamNameAmongExistingTeams.length !== 0 && (
                <div className="upload-duplicate-teams-list">
                    <span className="upload-duplicate-teams-title">Following teams will not be created (already exists)</span>
                    <div>
                        <span className="upload-duplicate-team-names">
                            {duplicateTeamNameAmongExistingTeams.join(', ')}
                        </span>
                    </div>
                </div>
            )}
        </DialogContent>

        <DialogActions>
          <Button 
            className='cancel-btn' 
            onClick={() => { 
              resetUpload(); 
              setPopupOpen(false) }
            } 
            color='primary'
          >
            Cancel
          </Button>
          <Button 
            onClick={UploadTeams} 
            color='primary'
            disabled={errors.length !== 0 || checkCompleted === false}
          >
            Confirm upload
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
