import React from 'react';
import {
  TableBody,
  TableCell,
  TableRow, Tooltip,
} from '@material-ui/core';
import {
  Create,
  RemoveRedEye,
  MailOutline,
} from '@material-ui/icons';
import uuid from 'react-uuid';
import Moment from 'react-moment';
import { useMutation } from '@apollo/client';
import Notify from '../../../utils/notify';
import { NOTIFY_COORDINATOR } from '../../../operations/mutations/notifyCoordintaor';
import { getEventState } from '../../../utils/util';

export default function TablePart({ events, setEventToEdit }) {
  const handleEdit = id => setEventToEdit(+id);

  const [notifyCoordinator] = useMutation(NOTIFY_COORDINATOR, {
    onCompleted: () => {
      Notify.notifyCoord();
    },
  });

  const handleMail = eventId => {
    notifyCoordinator({
      variables: {
        eventId,
        origin: `${window.location.origin}/event/`,
      },
    }).catch(e => console.error(e));
  };

  return (
    <TableBody>
      {events.map((event) => {
        const state = getEventState(event);
        return (
          <TableRow hover key={uuid()}>
            <TableCell align="center">
              {event.name}
            </TableCell>
            <TableCell align="center">
              {event.customer}
            </TableCell>
            <TableCell align="center">
              <Moment format="DD/MM/YYYY">
                {Number(event.startDatetime)}
              </Moment>
            </TableCell>
            <TableCell align="center">
              <Moment format="HH:mm">
                {Number(event.startDatetime)}
              </Moment>
            </TableCell>
            {event.description ? (
              <Tooltip title={event.description || ''} placement="top" arrow>
                <TableCell align="center" className="description">
                  {event.description}
                </TableCell>
              </Tooltip>
            ) : (
              <TableCell align="center" className="description"/>
            )}
            <TableCell align="center">
              {state}
            </TableCell>
            <TableCell align="center">
              <Create
                className={`admin-table-controls ${(state !== 'UPCOMING') && 'disabled'}`}
                onClick={() => handleEdit(event.id)}
              />
            </TableCell>
            <TableCell align="center">
              <a
                rel="noopener noreferrer"
                className={`${(state === 'CANCELED') ? 'disabled' : ''}`}
                target="_blank"
                href={`${window.location.origin}/event/${event.coordinator.urlIdentity}`}
              >
                <RemoveRedEye className={`admin-table-controls`} />
              </a>
            </TableCell>
            <TableCell align="center">
              <MailOutline
                className={`admin-table-controls ${(state === 'CANCELED') && 'disabled'}`}
                onClick={() => handleMail(event.id)}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}








