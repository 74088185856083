import React from 'react';
import { Box, Tooltip } from '@material-ui/core';
import CheckMark from '../CheckMark/CheckMarkComponent'
import './style.css';

export interface IAnswer {
  answerText: string
  isPuzzleSolved: boolean
}

const Answer: React.FC<IAnswer> = (
  {
    answerText,
    isPuzzleSolved,
  },
) => {
  return (
    <Box className="puzzle-stat" display="flex" alignItems="center">
      <Tooltip
        title={answerText}
        placement="top"
        arrow
      >
        <Box className="puzzle-result">
          {answerText}
        </Box>
      </Tooltip>
      <Box className="puzzle-tick">
        <CheckMark isPuzzleSolved={isPuzzleSolved}></CheckMark>
      </Box>
    </Box>
  );
};

export default Answer;
