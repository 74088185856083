import React, { useEffect, useState } from 'react';
import { Box, Typography} from '@material-ui/core';
import { useQuery, useSubscription } from '@apollo/client';
import { CHECK_WS_CONNECTION } from '../../operations/subscriptions/checkWSConnection'
import { CHECK_WS_CONNECTION_QUERY } from '../../operations/queries/checkWSConnectionQuery'
import ClipLoader from "react-spinners/ClipLoader";
import CheckMark from '../../components/Game/CheckMark/CheckMarkComponent'
import { Fade, Slide } from "react-awesome-reveal";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import uuid from 'react-uuid';

import './style.css';

const ConnectionTest: React.FC = () => {
  const [isWSConnectionEstablished, setWSConnectionEstablished] = useState(false)
  const [isHTTPConnectionEstablished, setHTTPConnectionEstablished] = useState(false)
  const [countOfAttempts, setCountOfAttempts] = useState(1)
  const [waitingForWSConnection, setWaitingForWSConnection] = useState(true)
  const [clientId, setClientId] = useState('')
  const timeToWaitWSConnection = parseInt(`${process.env.REACT_APP_TIME_TO_WAIT_WS_CONNECTION}`)

  useEffect(() => {
    setClientId(uuid())
  }, [])

  let { data: WSData } = useSubscription(CHECK_WS_CONNECTION, {
    variables: {
      clientId: clientId,
    },
    onSubscriptionData: () => {
      if (WSData) {
        setWSConnectionEstablished(true)
      }
    },
  });

  const { data: HTTPData, refetch } = useQuery(CHECK_WS_CONNECTION_QUERY, {
    variables: { clientId: clientId }
  });
  

  useEffect(() => {
    setTimeout(function() {
      setWaitingForWSConnection(false)
    }, timeToWaitWSConnection)
  }, [])

  useEffect(() => {
    if (waitingForWSConnection) {
      refetch()
      if (!isWSConnectionEstablished) {
        setTimeout(function() {
          setCountOfAttempts(countOfAttempts + 1);
        }, 3000)
      }
    }
  }, [countOfAttempts])

  useEffect(() => {
    if (HTTPData?.checkWSConnectionQuery) {
      setHTTPConnectionEstablished(true);
    }
  }, [HTTPData])

  useEffect(() => {
    if (isHTTPConnectionEstablished && isWSConnectionEstablished) {
      setWaitingForWSConnection(false)
    }
  }, [isWSConnectionEstablished, isHTTPConnectionEstablished])

  return (
    <Box className="connection-test-wrap" mt={2}>
      <Box className="connection-test-title" m={5}>
        <Typography variant={"h2"}>
          Team Building Arena
        </Typography>
      </Box>

      {!isHTTPConnectionEstablished && waitingForWSConnection ? 
        <Box className="connection-test-block">
          <Typography variant={"h4"} className="test-block-name">
            Checking HTTP connection
          </Typography>
          <ClipLoader color='orange' loading={true} size={30} />
        </Box>
       :
        <Box>
          {isHTTPConnectionEstablished ?
          <Box className="connection-test-block">
            <Typography variant={"h4"} className="test-block-name">
              HTTP Connection is established
            </Typography>
            <Slide direction="right">
              <CheckIcon style={{ color: 'green', fontSize: 40 }}/>
            </Slide>
          </Box>
          :
          <Box className="connection-test-block">
            <Typography variant={"h4"} className="test-block-name">
              Cannot connect to HTTP Server
            </Typography>
            <Slide direction="right">
              <ClearIcon style={{ color: 'red', fontSize: 40 }}/>
            </Slide>
          </Box>
          }
       </Box>
      }

      {!isWSConnectionEstablished && waitingForWSConnection ? 
        <Box className="connection-test-block">
          <Typography variant={"h4"} className="test-block-name">
            Checking connection to WebSocket Server
          </Typography>
          <ClipLoader color='orange' loading={true} size={30} />
        </Box>
        :
        <Box>
          {isWSConnectionEstablished ?
          <Box className="connection-test-block">
            <Typography variant={"h4"} className="test-block-name">
              WebSocket Connection is established
            </Typography>
            <Slide direction="right">
              <CheckIcon style={{ color: 'green', fontSize: 40 }}/>
            </Slide>
          </Box>
          :
          <Box className="connection-test-block">
            <Typography variant={"h4"} className="test-block-name">
              Cannot connect to WebSocket Server
            </Typography>
            <Slide direction="right">
              <ClearIcon style={{ color: 'red', fontSize: 40 }}/>
            </Slide>
          </Box>
          }
        </Box>
      }

      <Box>
        {!waitingForWSConnection && 
          <Box m={4}>
            {isWSConnectionEstablished && isHTTPConnectionEstablished ? 
              <Box className="connection-test-result">
                <CheckMark isPuzzleSolved={true}></CheckMark>
                <Fade duration={1000} triggerOnce>
                <Slide duration={500} delay={500} direction="up" triggerOnce>
                  <Typography variant={"h4"}>Ready for Team Building Arena!</Typography>
                </Slide>
                </Fade>
              </Box>
              :
              <Box className="connection-test-result">
                <Slide direction="up">
                  <ClearIcon style={{ color: 'red', fontSize: 60 }}/>
                </Slide>
                {!isWSConnectionEstablished &&
                <Fade duration={1000} delay={500} triggerOnce>
                  <Typography variant={"h4"}>Cannot connect to WebSocket Server</Typography>
                </Fade>
                }
                {!isHTTPConnectionEstablished &&
                <Fade duration={1000} delay={500} triggerOnce>
                  <Typography variant={"h4"}>Cannot connect to HTTP Server</Typography>
                </Fade>
                }
              </Box>
            }
          </Box>
        } 
      </Box>
    </Box>
  )
}

export default ConnectionTest;
