import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

export default (
  {
    setPopupOpen,
    isPopupOpen,
    reschedule,
    eventId,
  },
) => (
  <Dialog disableScrollLock open={isPopupOpen} onClose={() => {
    setPopupOpen(false);
  }}>
    <DialogTitle id="alert-dialog-slide-title">
      Start the game now
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
      Are you sure you want to start the game now?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setPopupOpen(false)} color="primary">
        Cancel
      </Button>
      <Button onClick={() => {
        const time = new Date();
        reschedule({
          variables: {
            startDatetime: time,
            id: eventId,
          },
        }).catch(e => console.error(e));
        setPopupOpen(false);
      }} color="primary">
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);
