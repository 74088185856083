import { gql } from '@apollo/client';

export const EDIT_TEAM = gql`
    mutation editTeam(
        $id: Int!,
        $name: String!,
        $notes: String!,
        $members: [MemberInput]!
    ) {
        editTeam(
            id: $id
            name: $name
            notes: $notes
            members: $members
        ) {
            id
        }
    }
`;
