import React from 'react';
import { Box } from '@material-ui/core';
import './style.css';

interface IStartWindowComponent {
  gameImageUrl: string
  gameName: string
  gameBeginningText: string
}

const StartWindow = React.memo<IStartWindowComponent>((
  {
    gameImageUrl,
    gameName,
    gameBeginningText,
  },
) => {
  return (
    <Box display="flex" width="100%" border={1} borderColor="#ddd" className="start-window">
      <Box className="start-img">
        <img src={`${process.env.REACT_APP_IMAGE_PATH}img/${gameImageUrl}`} alt="game" />
      </Box>

      <Box className="start-info" p={3}>
        <Box className="start-title">
          {gameName}
        </Box>
        <Box className="start-text" dangerouslySetInnerHTML={{__html: gameBeginningText}}/>
      </Box>
    </Box>
  );
});

export default StartWindow;
