import React, { useState } from 'react';
import {
  Table,
  Container,
  Grid,
  TableContainer,
  Toolbar,
  Paper,
  Box,
  Select,
  MenuItem,
} from '@material-ui/core';
import TableHead from './TableParts/tableHead';
import TableBody from './TableParts/tableBody';

export default ({rates}) => {

  const [condition, setCondition] = useState('score');
  const [order, setOrder] = useState('desc');

  return (
    <Container className="table-wrap monitoring-table-wrap">
      <Paper className="table-paper monitoring-table-paper">
        <Box>
          <Toolbar disableGutters>
            <Grid className="monitoring-table-toolbar" alignItems="center" container>
              <Select
                value={condition}
                onChange={(data) => {
                    setCondition(data.target.value)
                  }
                }
                className="sort-select"
              >
                <MenuItem value="score">Score</MenuItem>
                <MenuItem value="solvedPuzzles">Solved puzzles</MenuItem>
              </Select>
              <Select
                value={order}
                onChange={(data) => {
                    setOrder(data.target.value)
                  }
                }
                className="sort-select"
              >
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </Grid>
          </Toolbar>
        </Box>

        <TableContainer>
          <Table className="monitoring-table" aria-labelledby="tableTitle" aria-label="enhanced table">
            <TableHead />
            <TableBody
              rates={rates}
              condition={condition}
              order={order}
            />
          </Table>
        </TableContainer>
      </Paper>
    </Container>

  );
}
