import React from 'react';
import {
  Grid,
  Button,
  TextField,
} from '@material-ui/core';

const Form: React.FC<{
  values: { password: string },
  errors: any, // TODO
  touched: any,
  handleSubmit: any,
  handleChange: any,
}> = (
  {
    values: { password },
    errors,
    touched,
    handleSubmit,
    handleChange,
  },
) => {
  return (
    <form className='auth-form' onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            helperText={touched.password ? errors.password : ''}
            fullWidth
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            error={!!(errors.password)}
            value={password}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className='auth-btn'
      >
        Sign In
      </Button>
    </form>
  );
};

export default Form;
