import * as Yup from 'yup';

export const validationSchema = Yup.object({
  teamName: Yup.string()
    .required('Team name is required')
    .max(20, 'Team name must be less than 20 characters'),
  members: Yup
    .array()
    .compact(elem => (elem === undefined))
    .of(Yup.string()),
  notes: Yup.string(),
});
