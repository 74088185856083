import React, { useState } from 'react';
import {
  Table,
  Container,
  TableContainer,
  Toolbar,
  Typography,
  Paper,
  Box,
  Button,
} from '@material-ui/core';
import TableHead from './TableParts/tableHead';
import TableBody from './TableParts/tableBody';
import AddTeamPopup from './addTeam';
import EditTeamPopup from './editTeam';
import CopyTeamDetailsPopup from './mailDialog';
import Rates from './rates';
import UploadTeams from './uploadTeams';
import DownloadTeams from './downloadTeamsXSV';
import cryptoRandomString from 'crypto-random-string';
import './style.css';

export default function CoordinatorTable(
  {
    gameState,
    event,
    eventRefetch,
  },
) {
  const headCells = ['Number', 'Name', 'Members', 'Notes', 'Link', 'Action', 'Mail'];
  const [isCreatePopupOpen, setCreatePopupOpen] = useState(false);
  const [isUploadPopupOpen, setUploadPopupOpen] = useState(false);
  const [teamEventToEdit, setTeamEventToEdit] = useState(null);
  const [teamEventToCopy, setTeamEventToCopy] = useState(null);

  return (
    <Container className="table-wrap coord-wrap">
      <Paper className="table-paper">
        <Box>
          <Toolbar disableGutters>
            <Box className="table-title coord-title team-list">
              <Typography variant="h5" id="tableTitle" component="div">
                Teams
              </Typography>
              <Button
                type="submit"
                onClick={() => setUploadPopupOpen(true)}
                disabled={(gameState !== 'UPCOMING') || (event.teamsLimit === event.teamEvents.length)}
                variant="contained"
                color="primary"
                size="small"
                className='upload-teams-btn'
              >
                Upload Teams
              </Button>
              <DownloadTeams
                event={event}
                teamEvents={event.teamEvents}
              />
            </Box>
          </Toolbar>
        </Box>

        <TableContainer>
          <Table
            className="admin-table"
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead headCells={headCells} />
            <TableBody
              setCreatePopupOpen={setCreatePopupOpen}
              setTeamEventToEdit={setTeamEventToEdit}
              setTeamEventToCopy={setTeamEventToCopy}
              gameState={gameState}
              teamEvents={event.teamEvents}
              teamsLimit={event.teamsLimit}
            />
          </Table>
        </TableContainer>

        <AddTeamPopup
          isPopupOpen={isCreatePopupOpen}
          setPopupOpen={setCreatePopupOpen}
          eventId={event.id}
          eventUrl={event.coordinator.urlIdentity}
          hash={cryptoRandomString({ length: 5 })}
          eventRefetch={eventRefetch}
          teamEvents={event.teamEvents}
          maxPlayersInTeam={event.maxPlayersInTeam}
        />
        <EditTeamPopup
          isPopupOpen={teamEventToEdit !== null}
          setPopupOpen={setTeamEventToEdit}
          teamEvent={event.teamEvents[teamEventToEdit]}
          eventRefetch={eventRefetch}
          teamEvents={event.teamEvents}
        />
        <CopyTeamDetailsPopup
          isPopupOpen={teamEventToCopy !== null}
          setPopupOpen={setTeamEventToCopy}
          teamEvent={event.teamEvents[teamEventToCopy]}
          eventName={event.name}
          eventStartDatetime={new Date(+event.startDatetime)}
        />
        <UploadTeams
          isPopupOpen={isUploadPopupOpen}
          setPopupOpen={setUploadPopupOpen}
          teamEvents={event.teamEvents}
          maxPlayersInTeam={event.maxPlayersInTeam}
          event={event}
          eventId={event.id}
          eventRefetch={eventRefetch}
          eventUrl={event.coordinator.urlIdentity}
          teamsLimit={event.teamsLimit}
        />
      </Paper>

      {(gameState === 'ACTIVE' || gameState === 'FINISHED') && event.teamEvents.length && (
        <Rates event={event} gameState={gameState} />
      )}
    </Container>

  );
}
