import { gql } from '@apollo/client';

export const GET_CURRENT_TEAM_EVENT = gql`
    query currentTeamEvent($url: String!) {
        currentTeamEvent(url: $url) {
            totalTime
            score
            event {
                id
                name
                isCanceled
                startDatetime
                duration
                state
                coordinator {
                    login
                    email
                }
                game {
                    topImage
                    name
                    image
                    beginningText
                    finalImage
                    bgImage
                    finalText
                    puzzlesCount
                    waitingText
                    waitingImage
                }
                rates {
                    teamName
                    score
                    solvedPuzzlesCount
                    passedPuzzlesCount
                    usedHintsCount
                    totalTime
                }
            }
            team {
                name
                members {
                    name
                }
            }
            currentPuzzleStat {
                puzzle {
                    name
                    file
                    format
                }
                
                usedHints {
                    text
                }
                availableHintsCount
                lastHintUseDateTime
                mistakes {
                    createdAt
                }
            }
            previousPuzzleStats {
                puzzle {
                    name
                    file
                    answer
                }
                usedHints {
                    text
                }
                isPuzzleSolved
            }
        }
    }
`;
