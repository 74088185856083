import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { GET_CURRENT_USER } from '../operations/queries/getCurrentUser';
import { Box } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Lines as Loader } from 'react-preloaders';

import PrivateRoute from './HOC/PrivateRoute';
import PublicRoute from './HOC/PublicRoute';
import Main from '../pages/Main';
import AdminPanel from '../pages/AdminPanel';
import AdminLogin from '../pages/AdminLogin';
import CoordinatorLogin from '../pages/CoordinatorLogin';
import TeamLogin from '../pages/TeamLogin';
import EventStatus from '../pages/EventStatus';
import GameScreen from '../containers/GameScreen';
import ErrorPage from './errors/ErrorPage';
import ConnectionTest from './connectionTest/ConnectionTest'

const Navigation = () => {
  const { loading, error, data } = useQuery(GET_CURRENT_USER);

  if (loading) return <Loader background={'#333'} color="#fff" customLoading={loading} />;
  if (error) return <div className={'center'}>{error.toString()}</div>;
  const user = data.user;

  return (
    <Box>
      <Switch>
        <Route path='/' exact>
          <Main />
        </Route>
        <PrivateRoute path='/admin' exact user={user} role="admin">
          <AdminPanel />
        </PrivateRoute>
        <PublicRoute path='/admin/:id' exact user={user} role="admin">
          <AdminLogin />
        </PublicRoute>
        <PublicRoute path='/event/:id' exact user={user} role="coordinator">
          <CoordinatorLogin />
        </PublicRoute>
        <Route path='/event/:id/login' exact>
          <TeamLogin />
        </Route>
        <Route path='/event/:id/status' exact>
          <EventStatus />
        </Route>
        <Route path='/event/:id/:game+' exact>
          <GameScreen />
        </Route>
        <Route path='/no-permission' exact>
          <ErrorPage
            primaryText={'Permission Denied'}
            secondaryText={'Get your URL-link to enter login page'}
          />
        </Route>
        <Route path='/session-ended' exact>
          <ErrorPage
            primaryText={'Session Ended'}
            secondaryText={'You was logged out of Admin Panel'}
          />
        </Route>
        <Route path='/check' exact>
          <ConnectionTest />
        </Route>
        <Route path='*'>
          <ErrorPage
            primaryText={'404'}
            secondaryText={'Page Not Found'}
          />
        </Route>
      </Switch>

      <ToastContainer containerId={'one'} />
    </Box>
  );
};

export default Navigation;
