import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import TeamsRate from '../TeamsRate/TeamsRate';
import MobileNavigation from './MobileNavigation';
import HamIcon from '../../../img/my-ham.svg';
import './style.css';
import { IRate } from '../../../InterfacesFromDB';
import HowToPlay from '../../../HowToPlay';

interface IHeaderNavComponent {
  gameState: string
  coordinatorLogin: string
  coordinatorEmail: string
  memberNames: string[]
  topImageUrl: string
  teamRates: IRate[]
  eventId: number
  requestToRefetch: () => void
}

const HeaderNavComponent: React.FC<IHeaderNavComponent> = (
  {
    coordinatorLogin,
    coordinatorEmail,
    memberNames,
    gameState,
    topImageUrl,
    teamRates,
    eventId,
    requestToRefetch,
  },
) => {
  const [isTeamPopupOpen, setTeamPopupOpen] = useState(false);
  const [isCoordinatorPopupOpen, setCoordinatorPopupOpen] = useState(false);
  const [isHowToPlayPopupOpen, setHowToPlayPopupOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleClose = () => {
    setTeamPopupOpen(false);
    setCoordinatorPopupOpen(false);
    setHowToPlayPopupOpen(false);
  };

  return (
    <Box
      className="top-header-nav-block"
      display="flex"
      justifyContent="space-between"
      width="100%"
    >
      <MobileNavigation
        memberNames={memberNames}
        isMenuOpen={isMenuOpen}
        setMenuOpen={setMenuOpen}
        setTeamPopupOpen={setTeamPopupOpen}
        setCoordinatorPopupOpen={setCoordinatorPopupOpen}
        setHowToPlayPopupOpen={setHowToPlayPopupOpen}
      />

      <TeamsRate
        gameState={gameState}
        teamRates={teamRates}
        eventId={eventId}
        requestToRefetch={requestToRefetch}
      />

      <Box className="header-waiting-background-1">
        <img src={`${process.env.REACT_APP_IMAGE_PATH}img/topBG_image1.jpg`} alt='' />
      </Box>
      <Box className="header-waiting-white-background" />

      <Box className="nav-content">
        <Box className="nav-btns" width="100%">
          {memberNames.length == 0 ? 
            (
              <div></div>
            )
          :
            (
              (memberNames.length <= 1 && !memberNames[0]) ?
                (
                  <div></div>
                )
              :
                (
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className="left-btn"
                    onClick={() => setTeamPopupOpen(true)}
                  >
                    Your team
                  </Button> 
                )
            )
          }
          <Button
            size="small"
            variant="contained"
            color="primary"
            className="left-btn"
            onClick={() => setCoordinatorPopupOpen(true)}
          >
            Event coordinator
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setHowToPlayPopupOpen(true)}
            >
            How to play
          </Button>
          <div
            className="ham"
            onClick={() => setMenuOpen(true)}
            >
            <img alt="hamburger" src={HamIcon} />
          </div>
        </Box>

        <Box className="top-image" width="100%">
          <img src={`${process.env.REACT_APP_IMAGE_PATH}img/${topImageUrl}`} alt="game" />
        </Box>
      </Box>

      {/*********************
       *** Your Team Popup ***
       ***********************/}
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isTeamPopupOpen} fullWidth
              maxWidth="xs" disableScrollLock
              className="your-team-popup">
        <DialogTitle id="customized-dialog-title">
          Your Team
        </DialogTitle>
        <DialogContent className="dialog-content" dividers>
          <List>
            {memberNames
              .map((name, i) => (
                <ListItem key={i} disableGutters>
                  <ListItemText className="team-member" primary={`${name}`} />
                </ListItem>
              ))}
          </List>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/*****************************
       *** Event Coordinator Popup ***
       *******************************/}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isCoordinatorPopupOpen}
        fullWidth
        maxWidth="xs"
		    disableScrollLock
        className="event-coordinator-popup"
      >
        <DialogTitle id="customized-dialog-title">
          Event Coordinator
        </DialogTitle>
        <DialogContent className="dialog-content" dividers>
          <List disablePadding>
            <ListItem disableGutters>
              <PermIdentityIcon className="coordinator-icon" />
              <ListItemText className="coordinator-data" primary={coordinatorLogin} />
            </ListItem>
            <ListItem disableGutters>
              <MailOutlineIcon className="coordinator-icon" />
              <ListItemText className="coordinator-data" primary={coordinatorEmail} />
            </ListItem>
          </List>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/************************
       *** How To Play Popup ***
       *************************/}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isHowToPlayPopupOpen}
        className="how-to-play-popup"
      >
        <DialogTitle id="customized-dialog-title">
          How to Play
        </DialogTitle>
        <DialogContent dividers>
          <HowToPlay></HowToPlay>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HeaderNavComponent;
