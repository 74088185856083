import React, { useState, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { useMutation } from '@apollo/client';
import './style.css';
import AnswerForm from './AnswerForm';
import { SEND_ANSWER } from '../../../operations/mutations/sendAnswer';
import { FormikHelpers } from 'formik';
import { USE_HINT } from '../../../operations/mutations/useHint';
import { IMistake } from '../../../InterfacesFromDB';
import { GIVE_UP } from '../../../operations/mutations/giveUp';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

const BLOCKING_TIME = 9;
const REVEAL_TIME = 5;

export interface IPuzzleComponent {
  usedHints: string[]
  availableHintsCount: number
  startEventDateTime: Date
  lastHintUseDateTime: string
  quizNumber: number
  quizName: string
  eventId: number
  partPDF: string
  mistakes: IMistake[]
  requestToRefetch: () => void
  url: string
}

const Puzzle: React.FC<IPuzzleComponent> = (
  {
    //for hints
    usedHints,
    availableHintsCount,
    startEventDateTime,
    lastHintUseDateTime,
    quizNumber,
    quizName,
    eventId,
    partPDF,
    mistakes,
    requestToRefetch,
    url,
  },
) => {

  const [isAnswerFormBlocked, setIsBlocked] = useState(false);
  const [sendAnswerMutation] = useMutation(SEND_ANSWER,
    {
      onCompleted() {
        requestToRefetch()
      }
    });

  const [getHintMutation] = useMutation(USE_HINT,
    {
      onCompleted() {
        requestToRefetch()
      }
    });

  const [giveUpMutation] = useMutation(GIVE_UP,
    {
      onCompleted() {
        requestToRefetch()
      }
    });

  const handleUseHint = () => {
    getHintMutation({
      variables: {
        url,
      }
    }).catch(e => console.log(e));
  };

  const handleGiveUp = (resetForm: () => void) => {
    resetForm();
    giveUpMutation({
      variables: {
        url,
      }
    }).catch(e => console.log(e));
  };

  const handleAnswerCheck = (answer: string, action: FormikHelpers<{ answer: string; }>) => {
    sendAnswerMutation({
      variables: {
        url,
        answer: answer.trim().replace(/ /g, ''),
      },
      update: (cache, mutationResult) => {
        if (!mutationResult.data.sendAnswer.status) {
          action.setFieldError("answer","Wrong Answer");
        }
      }
    }).catch(e => console.log(e));
  };

  useEffect(() => {
    if (mistakes.length >= 3) {
      const firstMistakeTime = mistakes[mistakes.length - 3].createdAt;
      const lastMistakeTime = mistakes[mistakes.length - 1].createdAt;
      const threeMessagesInterval = ((Number(firstMistakeTime) - Number(lastMistakeTime)) / 1000);
      if ((threeMessagesInterval < BLOCKING_TIME) && (((Date.now() - Number(firstMistakeTime)) / 1000) < REVEAL_TIME)) {
        setIsBlocked(true);

        let interval = setInterval(() => {
          if (Date.now() > (Number(lastMistakeTime) + REVEAL_TIME * 1000)) {
            setIsBlocked(false);
            clearInterval(interval);
          }
        }, 500);
      }
    }
  }, [mistakes]);

  return (
    <SwitchTransition mode={'out-in'}>
      <CSSTransition
        key={quizNumber}
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
        classNames="fade"
      >
        <Box className="puzzle" display="flex" width="100%" flexDirection="column" mb={8}>
          <Box className="puzzle-title">
            Part {quizNumber}. <span>{quizName}</span>
          </Box>

          <Box className="puzzle-content" pt={2}>
            <Box className="puzzle-load">
              <Button
                className="pdf-button-wrap pdf-button"
                variant="contained"
                size="large"
                color="primary"
                component={'a'}
                href={`${process.env.REACT_APP_IMAGE_PATH}pdf/${eventId}/${partPDF}`}
                target="_blank"
                rel="noopener noreferrer"
              >

                  Access puzzle <InsertDriveFileOutlinedIcon className="pdf-icon" />

              </Button>
            </Box>

            <Box className="puzzle-interact">
              <AnswerForm
                handleAnswerCheck={handleAnswerCheck}
                isBlocked={isAnswerFormBlocked}
                usedHints={usedHints}
                eventStartDateTime={startEventDateTime}
                lastHintUseDateTime={lastHintUseDateTime}
                handleUseHint={handleUseHint}
                handleGiveUp={handleGiveUp}
                availableHintsCount={availableHintsCount}
              />
            </Box>
          </Box>
        </Box>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default Puzzle;
