import React, { useState, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import moment from 'moment';
import Countdown, { CountdownRendererFn } from 'react-countdown';
import './style.css';

interface IHintButton {
  hintNumber: number
  eventStartDateTime: Date
  lastHintUseDateTime: Date | null
  handleUseHint: () => void
}

const HintButton: React.FC<IHintButton> = (
  {
    hintNumber,
    eventStartDateTime,
    lastHintUseDateTime,
    handleUseHint,
  },
) => {
  const [isHintAvailable, setHintAvailable] = useState(false);

  const nextHintAvailabilityTime = (!lastHintUseDateTime)
    ? moment(eventStartDateTime)
      .add(process.env.REACT_APP_HINT_DELAY, 's')
      .toDate()
    : moment(lastHintUseDateTime)
      .add(process.env.REACT_APP_HINT_DELAY, 's')
      .toDate();

  const renderCountdown: CountdownRendererFn = ({ minutes, seconds }) => (
    <span>
      {minutes > 0 ? (minutes + (minutes > 1 ? ' minutes' : ' minute')) : ''} {seconds + ' seconds'}
    </span>
  );

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (nextHintAvailabilityTime.getTime() < Date.now()) {
      setHintAvailable(true);
    } else {
      setHintAvailable(false);
      interval = setInterval(() => {
        if (nextHintAvailabilityTime.getTime() < Date.now()) {
          setHintAvailable(true);
          clearInterval(interval);
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [nextHintAvailabilityTime]);

  //Render
  return (
    <Box display="flex" width="100%" flexDirection="column">
      <Box mt={2}>
        <Box>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Box mr={2}>
              <Button
                disabled={!isHintAvailable}
                className="hint-btn"
                onClick={handleUseHint}
                variant="contained"
                size="medium"
                color="primary">
                Hint {hintNumber}
              </Button>
            </Box>
            {!isHintAvailable && (
              <Box className="hint-timer">
                Hint available in <Countdown date={nextHintAvailabilityTime} renderer={renderCountdown} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HintButton;
