import React, { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import "./style.css"
import FlipCountdown from '@rumess/react-flip-countdown';
import clockIcon from '../../img/clock_sm.png'
import teamIcon from '../../img/team_sm.png';
import Footer from '../Game/Footer';
import Moment from 'react-moment';
import Countdown, { CountdownRendererFn } from 'react-countdown';
import EventStatusTable from '../EventStatusTable/index';
import { IEvent } from '../../InterfacesFromDB';

import { ICoordinatorEventData } from '../../InterfacesApollo';
import { useSubscription } from '@apollo/client';
import { UPDATE_RATES } from '../../operations/subscriptions/updateRates';
import { GET_COORDINATOR_EVENT } from '../../operations/queries/getCoordinatorEvent';
import { getEventUrlCode } from '../../utils/util';
import { UPDATE_EVENT_START_DATETIME } from '../../operations/subscriptions/updateEventStartDatetime';


interface IEventStatus {
    eventUrl: string
    event: IEvent
    gameState: string
}

const EventStatusComponent: React.FC<IEventStatus> = (
    {
        eventUrl,
        event,
        gameState,
    }
) => {

    const [countDownTo, setCountDownTo] = useState('00:00:00')
    const [eventStarted, setEventStarted] = useState(false);
    const [currentGameState, setCurrentGameState] = useState(gameState);

    useEffect(() => {
      if (event.rates.filter((team: { totalTime: number; }) => team.totalTime !== 0).length === event.rates.length) {
        setCurrentGameState('FINISHED');
      }
      if (event.state === 'FINISHED') {
        setCurrentGameState('FINISHED');
      }
    }, [event.rates])

    let startAt = new Date(+event.startDatetime);

    useEffect(() => {
        setCountDownTo(startAt.toString());
        const interval = setInterval(function () {
          if (startAt.getTime() > Date.now()) {
          }
          else {
            setEventStarted(true);
            clearInterval(interval);
          }
        }, 500)
    }, [startAt])
    
    const renderCountdown: CountdownRendererFn = ({ hours, minutes, seconds }) => (
        <span className="game-time-value">
            <span>{(hours < 10) ? '0' : ''}{hours}:{(minutes < 10) ? '0' : ''}{minutes}:{(seconds < 10) ? '0' : ''}{seconds}</span>
        </span>
    );

    useSubscription(
      UPDATE_RATES,
      {
        variables: {
          eventId: event.id,
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
          const cachedQuery: ICoordinatorEventData|null = client.cache.readQuery({
            query: GET_COORDINATOR_EVENT,
            variables: {
              url: eventUrl,
            },
          });
          client.writeQuery({
            query: GET_COORDINATOR_EVENT,
            variables: {
              url: getEventUrlCode(),
            },
            data: {
              coordinatorEvent: {
                ...cachedQuery?.coordinatorEvent,
                rates: subscriptionData.data.updateRates,
              },
            },
          });
        },
      },
    );
  
    useSubscription(
      UPDATE_EVENT_START_DATETIME,
      {
        variables: {
          eventId: event.id,
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
          const cachedQuery: ICoordinatorEventData|null = client.cache.readQuery({
            query: GET_COORDINATOR_EVENT,
            variables: {
              url: eventUrl,
            },
          });
          client.writeQuery({
            query: GET_COORDINATOR_EVENT,
            variables: {
              url: getEventUrlCode(),
            },
            data: {
              coordinatorEvent: {
                ...cachedQuery?.coordinatorEvent,
                startDatetime: Date.parse(subscriptionData.data.updateEventStartDatetime),
              },
            },
          });
        },
      },
    );

    return (
      <Box>
        <Container>
          <Box className='top-game-img-wrapper container container-big'>
            <Box className='top-game-img'>
              <Box className='top-image'>
                <img src={`${process.env.REACT_APP_IMAGE_PATH}img/${event.game.topImage}`} alt='game' />
              </Box>
            </Box>
          </Box>
          <div className='container container-big'>
            <Box
              className='game-all-info'
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              width='100%'
            >
              <Box className='header-background-2'>
                <img src={`${process.env.REACT_APP_IMAGE_PATH}img/topBG_image2.jpg`} alt='' />
              </Box>

              <Box className='event-info game-window'>
                <Typography className='event-name' variant='h4'>
                </Typography>
              </Box>

              <Box className='game-time-info'>
                {currentGameState === 'UPCOMING' && (
                  <Box className='time-to-wrap' display='flex' alignItems='center'>
                    <Box className='time-to'>
                      <Moment format='MMMM Do YYYY'>{startAt}</Moment>
                    </Box>
                    <Box className='time-to' ml={3}>
                      <Moment format='hh:mm a'>{startAt}</Moment>
                    </Box>
                  </Box>
                )}
                {currentGameState === 'ACTIVE' && (
                  <Box
                    className='game-time'
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box className='game-time-text'>TIME LEFT</Box>
                    <Countdown date={new Date(+event.startDatetime + event.duration)} renderer={renderCountdown} />
                  </Box>
                )}
                {currentGameState === 'FINISHED' && (
                  <Box
                    className='game-time'
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box className='game-time-text'>GAME FINISHED</Box>
                  </Box>
                )}
              </Box>
              <Box className='empty'></Box>
            </Box>
            <Box
              className='waiting-block'
              display='flex'
              alignItems='center'
              width='100%'
              flexDirection='column'
            >
              <Box
                className='top-header-nav-block'
                display='flex'
                justifyContent='space-between'
                width='100%'
              ></Box>
              <Box className='header-waiting-background-1'>
                <img src={`${process.env.REACT_APP_IMAGE_PATH}img/topBG_image1.jpg`} alt='' />
              </Box>
              <Box className='background-image'>
                {event.game.bgImage ? (
                  <img src={`${process.env.REACT_APP_IMAGE_PATH}img/${event.game.bgImage}`} alt='' />
                ) : null}
              </Box>
              <Container maxWidth='md' style={{ padding: 0 }}>
                <Box className='team-login-wrapper'>
                  <Box className='waiting-block-header' width='100%' border={1} borderColor='#ddd'>
                    <Box className='start-in'>
                      <span className='start-in-text'>
                        {startAt.getTime() > Date.now()
                          ? 'Game Starts in'
                          : (currentGameState === 'FINISHED'
                          ? 'Game is over'
                          : 'Game started')}
                      </span>
                      {startAt.getTime() > Date.now() && (
                        <FlipCountdown
                          dayTitle='Days'
                          hourTitle='Hours'
                          minuteTitle='Minutes'
                          secondTitle='Seconds'
                          hideYear
                          hideMonth
                          size='medium'
                          titlePosition='bottom'
                          endAt={countDownTo}
                        />
                      )}
                    </Box>
                    <Box className='game-waiting-image'>
                      <img
                        src={`${process.env.REACT_APP_IMAGE_PATH}img/${event.game.waitingImage}`}
                        alt='Game image'
                      />
                    </Box>
                  </Box>
                  <Box className='game-waiting-wrapper'>
                    <Box className='game-waiting-content'>
                      <div id='flipdown' className='flipdown'></div>
                      <Box className='game-waiting-content-header'>
                        <Box className='waiting-content-header-time'>
                          <img
                            className='waiting-content-header-icon'
                            src={clockIcon}
                            alt='hourglass'
                          />
                          <span>
                            {(
                              Math.abs((new Date(+event.startDatetime + event.duration).getTime() - new Date(+event.startDatetime).getTime())) /
                              60000
                            ).toString()}{' '}
                            minutes
                          </span>
                        </Box>
                        <Box className='waiting-content-header-teams'>
                          <img className='waiting-content-header-icon' src={teamIcon} alt='teams' />
                          <span>{event.rates.length} Teams Playing</span>
                        </Box>
                      </Box>

                      <Box>
                          <EventStatusTable
                            rates = {event.rates}
                          />
                      </Box>

                    </Box>
                  </Box>
                </Box>
              </Container>
            </Box>
          </div>
        </Container>
        <Footer />
      </Box>
    );
}

export default EventStatusComponent;