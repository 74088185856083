import {gql} from '@apollo/client';

export const ALL_GAMES_AND_EVENTS = gql`
    query($page: Int!, $limit: Int!, $sort: String!, $getCompleted: Boolean!) {
        allGamesAndEvents(page: $page, limit: $limit, sort: $sort, getCompleted: $getCompleted) {
            events {
                id
                name
                customer
                description
                teamsLimit
                maxPlayersInTeam
                duration
                startDatetime
                isCanceled
                gameId
                slackChannelName
                coordinator {
                    id
                    urlIdentity
                    login
                    email
                }
                rates {
                    totalTime
                }
            }
            eventsCount
            games {
                id
                name
            }
        }
    }
`
