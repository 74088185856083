import React, { useState } from 'react';
import AdminTable from '../components/AdminPanel';
import { useQuery } from '@apollo/client';
import { ALL_GAMES_AND_EVENTS } from '../operations/queries/getAllGamesAndEvents';
import { Lines as Loader } from 'react-preloaders';

const LIMIT = Number(process.env.REACT_APP_EVENTS_PER_PAGE);

export default () => {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState('startDatetime');
  const [showCompletedEvents, setShowCompletedEvents] = useState(false)

  const { loading, data, error, refetch } = useQuery(ALL_GAMES_AND_EVENTS, {
    variables: {
      page,
      limit: LIMIT,
      sort,
      getCompleted: showCompletedEvents,
    },
  });

  if (loading || !data) return <Loader background={'#333'} color="#fff" customLoading={loading} />;
  if (error) return <div className={'center'}>{error.toString()}</div>;

  return (
    <AdminTable
      events={data.allGamesAndEvents.events}
      eventsCount={data.allGamesAndEvents.eventsCount}
      games={data.allGamesAndEvents.games}
      limit={LIMIT}
      page={page}
      setPage={setPage}
      sort={sort}
      setSort={setSort}
      refetchEvents={refetch}
      showCompletedEvents={showCompletedEvents}
      setShowCompletedEvents={setShowCompletedEvents}
    />
  );
};
