import React from 'react';
import { Container, Box } from '@material-ui/core';
import Footer from '../../components/Game/Footer';
import './style.css';
import HeaderNav from '../../components/Game/HeaderNav/HeaderNavComponent';
import HeaderGameInfo from '../../components/Game/HeaderGameInfo/HeaderGameInfoComponent';
import GameWaiting from '../../components/Game/Waiting/GameWaitingComponent';
import StartWindow from '../../components/Game/StartWindow/StartWindowComponent';
import Puzzles from '../../components/Game/Puzzles/Puzzles';
import { IPuzzleStat, IRate } from '../../InterfacesFromDB';
import { useSubscription } from '@apollo/client';
import { UPDATE_RATES } from '../../operations/subscriptions/updateRates';
import { GET_CURRENT_TEAM_EVENT } from '../../operations/queries/getCurrentTeamEvent';
import { UPDATE_EVENT_START_DATETIME } from '../../operations/subscriptions/updateEventStartDatetime';
import { UPDATE_PUZZLE_STATS } from '../../operations/subscriptions/updatePuzzleStats';
import { ICurrentTeamEventData } from '../../InterfacesApollo';

interface IGamePage {
  gameState: string
  coordinatorLogin: string
  coordinatorEmail: string
  memberNames: string[]
  topImageUrl: string
  teamRates: IRate[]
  currentTeamRate: IRate | undefined
  startEventDateTime: Date
  endEventDateTime: Date
  eventName: string
  puzzlesCount: number
  teamsCount: number
  finishedTeamsCount: number
  gameImageUrl: string
  gameName: string
  gameBeginningText: string
  teamPlace: number
  gameFinalText: string
  gameFinalImageUrl: string
  eventId: number
  previousPuzzles: IPuzzleStat[]
  currentPuzzle: IPuzzleStat
  gameBGImage: string
  url: string
  waitingText: string,
  waitingImageUrl: string,
  score: number,
  requestToRefetch: () => void,
}

const GamePage: React.FC<IGamePage> = (
  {
    gameState,
    // for header
    coordinatorLogin,
    coordinatorEmail,
    memberNames,
    topImageUrl,
    teamRates,
    currentTeamRate,
    finishedTeamsCount,

    //for info header
    startEventDateTime,
    endEventDateTime,
    eventName,
    puzzlesCount,
    score,

    //for waiting screen
    teamsCount,
    waitingText,
    waitingImageUrl,

    //for starting screen
    gameImageUrl,
    gameName,
    gameBeginningText,

    //for puzzles
    previousPuzzles,
    currentPuzzle,
    teamPlace,
    gameFinalText,
    gameFinalImageUrl,
    eventId,

    gameBGImage,
    url,
    requestToRefetch,
  },
) => {
  useSubscription(UPDATE_PUZZLE_STATS, {
      variables: { url },
      onSubscriptionData: ({client, subscriptionData}) => {
        const cachedQuery: ICurrentTeamEventData|null = client.cache.readQuery({
          query: GET_CURRENT_TEAM_EVENT,
          variables: {
            url,
          },
        });
        client.writeQuery({
          query: GET_CURRENT_TEAM_EVENT,
          variables: { url },
          data: {
            currentTeamEvent: {
              ...cachedQuery?.currentTeamEvent,
              currentPuzzleStat: subscriptionData.data.updatePuzzleStats.currentPuzzleStat,
              previousPuzzleStats: subscriptionData.data.updatePuzzleStats.previousPuzzleStats,
              score: subscriptionData.data.score
            },
          },
        });
      },
    },
  );

  useSubscription(
    UPDATE_RATES,
    {
      variables: {
        eventId,
      },
      onSubscriptionData: async ({ client, subscriptionData }) => {
        const cachedQuery: ICurrentTeamEventData|null = client.cache.readQuery({
          query: GET_CURRENT_TEAM_EVENT,
          variables: {
            url,
          },
        });
        client.writeQuery({
          query: GET_CURRENT_TEAM_EVENT,
          variables: {
            url,
          },
          data: {
            currentTeamEvent: {
              ...cachedQuery?.currentTeamEvent,
              event: {
                ...cachedQuery?.currentTeamEvent.event,
                rates: subscriptionData.data.updateRates.rates,
              },
            },
          },
        });
      },
    },
  );

  useSubscription(
    UPDATE_EVENT_START_DATETIME,
    {
      variables: {
        eventId,
      },
      onSubscriptionData: ({ client, subscriptionData }) => {
        const cachedQuery: ICurrentTeamEventData|null = client.cache.readQuery({
          query: GET_CURRENT_TEAM_EVENT,
          variables: {
            url,
          },
        });
        client.writeQuery({
          query: GET_CURRENT_TEAM_EVENT,
          variables: {
            url,
          },
          data: {
            currentTeamEvent: {
              ...cachedQuery?.currentTeamEvent,
              event: {
                ...cachedQuery?.currentTeamEvent.event,
                startDatetime: Date.parse(subscriptionData.data.updateEventStartDatetime),
              },
            },
          },
        });
      },
    },
  );

  return (
    <Box minHeight="100vh">
      <Box minHeight="100vh" display="flex" flexDirection="column">
        <Container>
          <Box className={gameState !== 'UPCOMING' ? 'content-container' : ''}>
            <Box className={gameState !== 'UPCOMING' ? 'header' : 'waiting-header'}>
              <div className="container container-big">
                <HeaderNav
                  gameState={gameState}
                  coordinatorLogin={coordinatorLogin}
                  coordinatorEmail={coordinatorEmail}
                  memberNames={memberNames}
                  topImageUrl={topImageUrl}
                  teamRates={teamRates}
                  eventId={eventId}
                  requestToRefetch={requestToRefetch}
                />
                <HeaderGameInfo
                  gameState={gameState}
                  startEventDateTime={startEventDateTime}
                  endEventDateTime={endEventDateTime}
                  eventName={eventName}
                  teamName={currentTeamRate ? currentTeamRate.teamName : ''}
                  solvedPuzzlesCount={currentTeamRate ? currentTeamRate.solvedPuzzlesCount : 0}
                  quizzesCount={puzzlesCount}
                  totalTime={currentTeamRate ? currentTeamRate.totalTime : 0}
                  passedQuestionsCount={currentTeamRate ? currentTeamRate.passedPuzzlesCount : 0}
                  usedHintsCount={currentTeamRate ? currentTeamRate.usedHintsCount : 0}
                  finishedTeamsCount={finishedTeamsCount}
                  teamsCount={teamsCount}
                  teamPlace={teamPlace}
                  score={score}
                />
              </div>
            </Box>
            {gameState !== 'UPCOMING' ? (
              <Container maxWidth="md" style={{ padding: 0 }}>
                <Box>
                  <StartWindow
                    gameImageUrl={gameImageUrl}
                    gameName={gameName}
                    gameBeginningText={gameBeginningText}
                  />
                  <Puzzles
                    gameState={gameState}
                    teamPlace={teamPlace}
                    gameFinalText={gameFinalText}
                    currentTeamRate={currentTeamRate}
                    gameFinalImageUrl={gameFinalImageUrl}
                    startEventDateTime={startEventDateTime}
                    eventId={eventId}
                    previousPuzzles={previousPuzzles}
                    currentPuzzle={currentPuzzle}
                    quizzesCount={puzzlesCount}
                    score={score}
                    requestToRefetch={requestToRefetch}
                    url={url}
                  />
                </Box>
              </Container>
            ) : (
              <GameWaiting
                startEventDateTime={startEventDateTime}
                endEventDateTime={endEventDateTime}
                teamsCount={teamsCount}
                waitingText={waitingText}
                waitingImageUrl={waitingImageUrl}
              />
            )}
          </Box>
        </Container>

        <Box className="background-image">
          {gameBGImage ? (
            <img
            src={`${process.env.REACT_APP_IMAGE_PATH}img/${gameBGImage}`}
              alt='' />
          ) : null}

        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default (GamePage);









