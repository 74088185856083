import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import { Box } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Formik, FieldArray } from 'formik';
import { validationSchema } from './addValidation';
import apolloClient from '../../utils/apolloClient';
import { parseError } from '../../utils/parseGraphqlError';
import { ADD_TEAM } from '../../operations/mutations/addTeam';
import Notify from '../../utils/notify';
import { getRandomColor } from '../../utils/util';

export default (
  {
    isPopupOpen,
    setPopupOpen,
    eventId,
    hash,
    teamEvents,
    eventUrl,
    eventRefetch,
    maxPlayersInTeam,
  },
) => (
  <div>
    <Dialog
      disableBackdropClick={true}
      open={isPopupOpen}
      onClose={() => setPopupOpen(false)}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
	    disableScrollLock
    >
      <DialogTitle id="form-dialog-title">
        Add Team
      </DialogTitle>

      <Formik
        className="team-form"
        initialValues={{
          teamName: '',
          members: new Array(Number(maxPlayersInTeam ?? process.env.REACT_APP_TEAM_MEMBERS_COUNT)).fill(''),
          notes: '',
        }}
        validationSchema={validationSchema}
        onSubmit={({ teamName, notes, members }, { setFieldError }) => {
          if (teamEvents.some(teamEvent => teamEvent.team.name === teamName)) {
            setFieldError('teamName', 'Team name should be unique');
            return;
          }

          apolloClient.mutate({
            mutation: ADD_TEAM,
            variables: {
              name: teamName,
              notes: notes,
              eventId: eventId,
              url: `${eventUrl}/${hash.toUpperCase()}`,
              members: members,
              color: getRandomColor(teamEvents.length, eventId),
            },
          }).then(() => {
            eventRefetch();
            setPopupOpen(false);
            Notify.addTeam();
          }).catch(e => {
            const { field, text } = parseError(e);
            setFieldError(field, text);
          });
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => {
          return (
            <>
              <DialogContent className="add-team-body">
                <form onSubmit={handleSubmit}>
                  <Box display="flex" flexDirection="column">
                    <Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        helperText={(touched.teamName && errors.teamName) && errors.teamName}
                        name="teamName"
                        label="Team Name"
                        error={Boolean(errors.teamName) && touched.teamName}
                        value={values.teamName}
                        onChange={handleChange}
                      />
                    </Box>

                    <Box className="team-form-title">
                      Team Members
                    </Box>

                    <FieldArray name={'members'}>
                      {(arrayHelpers) => (
                        values.members.map((elem, index) => (
                          <Box key={index} className="member-input">
                            <TextField
                              fullWidth
                              helperText={
                                errors
                                && !index
                                && touched
                                && touched.members
                                && errors.members
                              }
                              name={`members.${index}`}
                              placeholder={'Team Member ' + (index + 1)}
                              error={
                                errors
                                && !index
                                && errors.members
                                && touched
                                && touched.members
                              }
                              value={values.members[index]}
                              onChange={handleChange}
                            />
                          </Box>
                        ))
                      )}
                    </FieldArray>

                    <Box className="team-form-title">
                      Team Notes
                    </Box>

                    <Box className="member-input notes">
                      <TextField
                        fullWidth
                        helperText={(touched.notes && errors.notes) && errors.notes}
                        name="notes"
                        placeholder="Notes"
                        rows={4}
                        multiline
                        variant="outlined"
                        error={(touched.notes && errors.notes)}
                        value={values.notes}
                        onChange={handleChange}
                      />
                    </Box>

                    <Box className="team-form-title">
                      Team Link
                    </Box>

                    <Box className="team-form-link">
                      {`${window.location.origin}/event/${eventUrl}/${hash}`}
                    </Box>
                  </Box>
                </form>
              </DialogContent>

              <DialogActions>
                <Button
                  className="cancel-btn"
                  onClick={() => setPopupOpen(false)}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                  Add
                </Button>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  </div>
);
