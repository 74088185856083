import React from 'react';
import {Typography, Box} from '@material-ui/core';

function HowToPlay () {
    return (
        <>
            <Box>
                <Typography variant={"h2"} className="subtitle">Overview</Typography>
                    <Typography variant={"body1"} className="text">
                        There are 8 puzzle missions in <b><i>Chaos at Chronides</i></b> game. Each mission contains a puzzle that you have to solve before moving on. The answer to each puzzle unlocks the next mission.
                    </Typography>
                    <Typography variant={"body1"} className="text">
                        <b>The answer to each puzzle is one or more words.</b>
                    </Typography>
                    <Typography variant={"body1"} className="text">
                        This is an escape room style game. What this means is that sometimes you have to figure out what the puzzle is before solving it.When solving, examine the page carefully, look for clues. Each mission has everything you need to solve the puzzle.Have a pencil and paper ready, they might be helpful. Talk to your teammates! Communication is the key to victory in this game.
                    </Typography>

                <Typography variant={"h2"} className="subtitle">Connecting</Typography>
                    <Typography variant={"body1"} className="text">
                        You are on the team’s page. This is the page that tracks your progress. You can use your favorite video conferencing or chat software to discuss the puzzles with your teammates.
                    </Typography>

                <Typography variant={"h2"} className="subtitle">Teams</Typography>
                    <Typography variant={"body1"} className="text">
                        All teams start at the same time. You can always view the status of other  teams by clicking the “leaderboard” button.
                    </Typography>

                <Typography variant={"h2"} className="subtitle">How to Win</Typography>
                    <Typography variant={"body1"} className="text">
                        Once your team solves a puzzle, enter the answer. Any member of the team can enter the answer. Don’t be afraid to make a mistake - there is no penalty for trying a wrong answer. The team that solves all the puzzles first with the least number of hints or given up questions wins!
                    </Typography>
                
                <Typography variant={"body1"} className="subtitle subtitle-note"><b><i>Hints</i></b></Typography>
                    <Typography variant={"body1"} className="text">
                        Feeling stuck? You can always get a hint. Each puzzle has three hints. Use the hints sparingly, they do decrease your team’s score.
                    </Typography>
                <Typography variant={"body1"} className="subtitle subtitle-note"><b><i>Passing the question</i></b></Typography>
                    <Typography variant={"body1"} className="text">
                        If your team used all of the hints and still cannot solve the puzzle, you can pass the question. This will allow your team to move on. Your score will be greatly reduced. However, there is still a chance to win!
                    </Typography>
            </Box>
        </>
    )
}

export default HowToPlay;