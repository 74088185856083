import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import GamePage from '../pages/Game/GamePage';
import { useQuery, useSubscription } from '@apollo/client';
import { Lines as Loader } from 'react-preloaders';
import { getEventState, getEventUrlCode } from '../utils/util';
import ErrorPage from '../navigation/errors/ErrorPage';
import { ICurrentTeamEventData, ICurrentTeamEventVars } from '../InterfacesApollo';
import { GET_CURRENT_TEAM_EVENT } from '../operations/queries/getCurrentTeamEvent';
import { CHECK_WS_CONNECTION } from '../operations/subscriptions/checkWSConnection'
import { CHECK_WS_CONNECTION_QUERY } from '../operations/queries/checkWSConnectionQuery'
import { UPDATE_GAME_STATE } from '../operations/subscriptions/updateGameState';

const GameScreen = () => {
  const [waitingForWSConnection, setWaitingForWSConnection] = useState(true)
  const [isWSConnectionEstablished, setWSConnectionEstablished] = useState(false)
  const [countOfAttempts, setCountOfAttempts] = useState(1)
  const [useRefetchToKeepDataUpdated, setUseRefetchToKeepDataUpdated] = useState(false)
  const timeToWaitWSConnection = parseInt(`${process.env.REACT_APP_TIME_TO_WAIT_WS_CONNECTION}`)
  const timeToRefetchGameData = parseInt(`${process.env.REACT_APP_TIME_BETWEEN_REFETCHING_GAME_DATA}`)
  const [clientId, setClientId] = useState('')
  
  useEffect(() => {
    setClientId(uuid())
  }, [])

  let { data: WSData } = useSubscription(CHECK_WS_CONNECTION, {
    variables: {
      clientId,
    },
    onSubscriptionData: () => {
      if (WSData) {
        console.log('Connected to Arena Server v.' + WSData.checkWSConnection + ' - WebSockets')
        setWSConnectionEstablished(true)
      }
    },
  });
  
  const { refetch: queryToCheckWSConnection } = useQuery(CHECK_WS_CONNECTION_QUERY, {
    variables: { clientId: clientId }
  });

  useEffect(() => {
    setTimeout(function() {
      setWaitingForWSConnection(false)
    }, timeToWaitWSConnection)
  }, [])

  useEffect(() => {
    if (waitingForWSConnection) {
      queryToCheckWSConnection()
      if (!isWSConnectionEstablished) {
        setTimeout(function() {
          setCountOfAttempts(countOfAttempts + 1);
        }, 4000)
      }
    }
  }, [countOfAttempts])

  useEffect(() => {
    if (isWSConnectionEstablished) {
      setWaitingForWSConnection(false)
    }
  }, [isWSConnectionEstablished])

  useEffect(() => {
    if (!waitingForWSConnection && !isWSConnectionEstablished) {
      console.log('Cannot connect to WebSocket Server.')
      console.log('Switched to use alternative method.')
      setUseRefetchToKeepDataUpdated(true)
    }
  }, [waitingForWSConnection])

  useEffect(() => {
    if (useRefetchToKeepDataUpdated) {
      refetch()
      setInterval(function() {
        refetch()
      }, timeToRefetchGameData)
    }
  }, [useRefetchToKeepDataUpdated])

  const requestToRefetch = () => {
    if (useRefetchToKeepDataUpdated) {
      refetch()
    }
  }

  let url = getEventUrlCode();

  const args = url.split('?');
  const urlParams = args[0].split('/').filter(element => element !== "");
  const gameUrlParams = urlParams.slice(2);
  
  const gameUrl = urlParams[0] + '/' + urlParams[1];
  url = gameUrl;

  let queryParams = null;
  if (args[1]) { queryParams = args[1].split('&'); }

  const {
    loading,
    data,
    error,
    refetch,
  } = useQuery<ICurrentTeamEventData, ICurrentTeamEventVars>(GET_CURRENT_TEAM_EVENT, {
    variables: { url },
  });

  const [gameState, setGameState] = useState('UPCOMING');

  useEffect(() => {
    if (data && data.currentTeamEvent) {
      if (gameState !== data.currentTeamEvent.event.state) {
        setGameState(data.currentTeamEvent.event.state);
      }
      if (!data.currentTeamEvent.event.state || data.currentTeamEvent.event.state === 'NULL') {
        setGameState(getEventState(data.currentTeamEvent.event));
      }
    }
  }, [data])
  
  const {data: newGameState} = useSubscription(UPDATE_GAME_STATE, {
    variables: { url },
    onSubscriptionData: () => {},
  })

  useEffect(() => {
    if (newGameState) {
      setGameState(newGameState.updatedGameState);
    }
  }, [newGameState])

  if (loading) return <Loader background={'#333'} color="#fff" customLoading={loading} />;
  if (error) return <div className={'center'}>{error.toString()}</div>;

  if (!data) return null;

  const { currentTeamEvent } = data;

  if (!currentTeamEvent) {
    return (
      <ErrorPage
        primaryText={'Wrong URL'}
        secondaryText={'There\'s no game page by this URL'}
      />
    );
  }

  if (gameState === 'CANCELED') {
    return (
      <ErrorPage
        primaryText={'Canceled Event'}
        secondaryText={'Event with this URL was canceled'}
      />
    );
  }

  const { event, team } = currentTeamEvent;

  return (
    <GamePage
      gameState={gameState}
      coordinatorLogin={event.coordinator.login}
      coordinatorEmail={event.coordinator.email}
      memberNames={
        team.members
          .map(member => member.name)
          .filter(name => name !== '')
      }
      topImageUrl={event.game.topImage}
      teamRates={event.rates}
      currentTeamRate={event.rates.find(rate => rate.teamName === team.name)}

      //for info header
      startEventDateTime={new Date(+event.startDatetime)}
      endEventDateTime={new Date(+event.startDatetime + event.duration)}
      eventName={event.name}
      puzzlesCount={event.game.puzzlesCount}
      score={currentTeamEvent.score}

      //for waiting screen
      teamsCount={event.rates.length}
      finishedTeamsCount={event.rates.filter(team => team.totalTime !== 0).length}
      waitingText={event.game.waitingText}
      waitingImageUrl={event.game.waitingImage}

      //for starting screen
      gameImageUrl={event.game.image}
      gameName={event.game.name}
      gameBeginningText={event.game.beginningText}

      //for puzzles
      teamPlace={event.rates.findIndex(rate => rate.teamName === team.name) + 1}
      gameFinalText={event.game.finalText}
      gameFinalImageUrl={event.game.finalImage}
      previousPuzzles={currentTeamEvent.previousPuzzleStats}
      currentPuzzle={currentTeamEvent.currentPuzzleStat}
      eventId={event.id}

      gameBGImage={event.game.bgImage}
      url={url}
      requestToRefetch={requestToRefetch}
    />
  );
};

export default GameScreen;
