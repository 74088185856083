import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Formik } from 'formik';
import { validationSchema } from './addValidation';
import apolloClient from '../../utils/apolloClient';
import { ADD_EVENT } from '../../operations/mutations/addEvent';
import { parseError } from '../../utils/parseGraphqlError';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminForm = ({ games, refetchEvents }) => {

  const [isPopupOpen, setPopupOpen] = useState(false);

  const handleClickOpen = () => {
    setPopupOpen(true);
  };
  const handleClose = () => {
    setPopupOpen(false);
  };

  return (
    <div>
      <Button
        position="right"
        variant="contained"
        color="primary"
        className="add-event-btn"
        onClick={handleClickOpen}
      >
        New Event
      </Button>


      <Dialog
        fullScreen
        open={isPopupOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
		    disableScrollLock
      >
        <AppBar className="app-bar">
          <Container>
            <Toolbar className="toolbar">
              <Typography variant="h6">
                Add Event
              </Typography>

              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </Container>
        </AppBar>

        <Container className="add-event-form">
          <Formik
            initialValues={{
              eventName: '',
              eventUrl: '',
              customer: '',
              date: '',
              time: '12:00',
              duration: 90,
              coordinatorName: '',
              coordinatorEmail: '',
              coordinatorPassword: '',
              gameId: '',
              teamsNumber: 2,
              maxPlayersInTeam: 6,
              description: '',
              slackChannelName: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setFieldError }) => {
              apolloClient.mutate({
                mutation: ADD_EVENT,
                variables: {
                  name: values.eventName,
                  url: values.eventUrl,
                  customer: values.customer,
                  startDatetime: new Date(`${values.date} ${values.time}`),
                  duration: values.duration * 60 * 1000,
                  coordinatorName: values.coordinatorName,
                  coordinatorEmail: values.coordinatorEmail,
                  coordinatorPassword: values.coordinatorPassword,
                  gameId: +values.gameId,
                  teamsLimit: values.teamsNumber,
                  maxPlayersInTeam: values.maxPlayersInTeam,
                  description: values.description,
                  slackChannelName: values.slackChannelName,
                },
              }).then(() => {
                refetchEvents()
                setPopupOpen(false);
              }).catch(e => {
                const { field, text } = parseError(e);
                setFieldError(field, text);
              });
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Box className="form-wrap" display="flex" flexDirection="column">
                    <Box display="flex">
                      <Box>
                        <TextField
                          variant="outlined"
                          helperText={(touched.eventName && errors.eventName) && errors.eventName}
                          name="eventName"
                          label="Event Name"
                          error={(touched.eventName && errors.eventName)}
                          value={values.eventName}
                          onChange={handleChange}
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="outlined"
                          helperText={(touched.eventUrl && errors.eventUrl) && errors.eventUrl}
                          name="eventUrl"
                          label="Event URL"
                          error={(touched.eventUrl && errors.eventUrl)}
                          value={values.eventUrl}
                          onChange={handleChange}
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="outlined"
                          helperText={(touched.customer && errors.customer) && errors.customer}
                          name="customer"
                          label="Customer"
                          error={(touched.customer && errors.customer)}
                          value={values.customer}
                          onChange={handleChange}
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box className="datetime-wrap">
                        <TextField
                          variant="outlined"
                          helperText={(touched.date && errors.date) && errors.date}
                          type="date"
                          value={values.date}
                          name="date"
                          error={(touched.date && errors.date)}
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>

                      <Box className="datetime-wrap">
                        <TextField
                          variant="outlined"
                          type="time"
                          value={values.time}
                          name="time"
                          helperText={(touched.time && errors.time) && errors.time}
                          error={(touched.time && errors.time)}
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300,
                          }}
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="outlined"
                          helperText={(touched.duration && errors.duration) && errors.duration}
                          name="duration"
                          type="number"
                          label="Event Duration"
                          error={(touched.duration && errors.duration)}
                          value={values.duration}
                          onChange={handleChange}
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box>
                        <TextField
                          variant="outlined"
                          helperText={(touched.coordinatorName && errors.coordinatorName) && errors.coordinatorName}
                          name="coordinatorName"
                          label="Coordinator Name"
                          error={(touched.coordinatorName && errors.coordinatorName)}
                          value={values.coordinatorName}
                          onChange={handleChange}
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="outlined"
                          helperText={(touched.coordinatorEmail && errors.coordinatorEmail) && errors.coordinatorEmail}
                          name="coordinatorEmail"
                          label="Coordinator Email"
                          error={(touched.coordinatorEmail && errors.coordinatorEmail)}
                          value={values.coordinatorEmail}
                          onChange={handleChange}
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="outlined"
                          helperText={(touched.coordinatorPassword && errors.coordinatorPassword) && errors.coordinatorPassword}
                          name="coordinatorPassword"
                          type="password"
                          label="Coordinator Password"
                          error={(touched.coordinatorPassword && errors.coordinatorPassword)}
                          value={values.coordinatorPassword}
                          onChange={handleChange}
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box>
                        <FormControl variant="outlined" className="form-control">
                          <InputLabel id="demo-simple-select-outlined-label">Game</InputLabel>
                          <Select
                            value={values.gameId}
                            onChange={handleChange}
                            error={(touched.gameId && errors.gameId)}
                            label="Game"
                            name="gameId"
                          >
                            {games.map((elem, index) => (
                              <MenuItem key={index} value={elem.id}>{elem.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>

                      <Box>
                        <TextField
                          variant="outlined"
                          helperText={(touched.teamsNumber && errors.teamsNumber) && errors.teamsNumber}
                          name="teamsNumber"
                          type="number"
                          label="Number of teams"
                          error={(touched.teamsNumber && errors.teamsNumber)}
                          value={values.teamsNumber}
                          onChange={handleChange}
                        />
                      </Box>

                      <Box>
                        <FormControl variant="outlined" className="form-control">
                          <InputLabel id="demo-simple-select-outlined-label">Maximal number of players in a team</InputLabel>
                          <Select
                            value={values.maxPlayersInTeam}
                            onChange={handleChange}
                            error={(touched.maxPlayersInTeam && errors.maxPlayersInTeam)}
                            label="Maximal number of players in a team"
                            name="maxPlayersInTeam"
                          >
                            {Array.from(new Array(10), (x,i) => i+1).map((elem, index) => (
                              <MenuItem key={index} value={elem}>{elem}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>

                      <Box>
                        <TextField
                          variant="outlined"
                          name="slackChannelName"
                          label="Slack channel name"
                          value={values.slackChannelName}
                          onChange={(e) => {
                            setFieldValue('slackChannelName', e.target.value.replaceAll(' ', '-'));
                          }}
                          helperText={errors.slackChannelName}
                          error={errors.slackChannelName}
                        />
                      </Box>
                      
                    </Box>
                    <Box className="textarea-wrap">
                      <TextField
                        label="Notes"
                        multiline
                        className="textarea-input"
                        rows={4}
                        value={values.description}
                        onChange={handleChange}
                        name="description"
                        variant="outlined"
                        helperText={(touched.description && errors.description) && errors.description}
                        error={(touched.description && errors.description)}
                      />
                    </Box>

                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      className="add-event-save-btn"
                    >
                      Save
                    </Button>
                  </Box>
                </form>
              );
            }}
          </Formik>
        </Container>
      </Dialog>
    </div>
  );
};

export default AdminForm;
