import React, { useState } from 'react';
import {
  Table,
  Container,
  Grid,
  TableContainer,
  TablePagination,
  Toolbar,
  Typography,
  Paper,
  Box,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import TableHead from './TableParts/tableHead';
import TableBody from './TableParts/tableBody';
import AddEventPopup from './addEvent';
import EditEvent from './editEvent';
import './style.css';

export default (
  {
    events,
    eventsCount,
    games,
    limit,
    page,
    setPage,
    sort,
    setSort,
    refetchEvents,
    showCompletedEvents,
    setShowCompletedEvents,
  },
) => {
  const [eventToEdit, setEventToEdit] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const sortData = (data) => {
    setPage(0);
    setSort(data.target.value);
  };

  const logout = () => {
    localStorage.removeItem('token');
    window.location.href = '/session-ended';
  };

  return (
    <Container className="table-wrap">
      <Paper className="table-paper">
        <Box pb={2}>
          <Toolbar disableGutters>
            <Grid className="admin-top-wrap" alignItems="center" container>
              <Box className="table-title">
                <Typography variant="h5" id="tableTitle" component="div"> Events </Typography>
              </Box>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={showCompletedEvents}
                    onChange={ function(){
                      setShowCompletedEvents(!showCompletedEvents)
                    }}
                    name="showCompletedEvents"
                    color="primary"
                  />
                }
                label="Show completed events"
              />

              <Select
                value={sort}
                onChange={sortData}
                className="sort-select"
              >
                <MenuItem value="startDatetime">Date</MenuItem>
                <MenuItem value="name">Event Name</MenuItem>
                <MenuItem value="customer">Customer</MenuItem>
              </Select>
              <AddEventPopup
                className="add-event-block"
                games={games}
                refetchEvents={refetchEvents}
              />
            </Grid>
          </Toolbar>
        </Box>

        <TableContainer>
          <Table className="admin-table" aria-labelledby="tableTitle" aria-label="enhanced table">
            <TableHead />
            <TableBody
              events={events}
              setEventToEdit={setEventToEdit}
            />
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[]}
          labelRowsPerPage=''
          component="div"
          count={eventsCount}
          rowsPerPage={limit}
          page={page}
          onChangePage={handleChangePage}
        />
      </Paper>


      <Box className="admin-top-panel">
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={logout}
        >
          Logout
        </Button>
      </Box>


      <EditEvent
        games={games}
        event={events.find(event => event.id === eventToEdit)}
        setEventToEdit={setEventToEdit}
        refetchEvents={refetchEvents}
      />
    </Container>

  );
}
