import React, { useState, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import moment from 'moment';
import Countdown, { CountdownRendererFn } from 'react-countdown';
import HintDialog from './Dialog';
import './style.css';

interface GiveUpButton {
  eventStartDateTime: Date
  lastHintUseDateTime: Date | null
  handleGiveUp: any
  resetForm: () => void
}

const GiveUpButton: React.FC<GiveUpButton> = (
  {
    eventStartDateTime,
    lastHintUseDateTime,
    handleGiveUp,
    resetForm,
  },
) => {

  const GIVE_UP_DELAY = process.env.REACT_APP_GIVE_UP_DELAY;
  const eventStartedAt = new Date(eventStartDateTime).toUTCString();

  const [isGiveUpAvailable, setGiveUpAvailable] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const nextGiveUpTime = lastHintUseDateTime
    ? moment(lastHintUseDateTime).add(GIVE_UP_DELAY, 's').toDate()
    : moment(eventStartedAt).add(GIVE_UP_DELAY, 's').toDate();

  const renderCountdown: CountdownRendererFn = ({ minutes, seconds }) => (
    <span>
      {minutes > 0 ? (minutes + (minutes > 1 ? ' minutes' : ' minute')) : ''} {seconds + ' seconds'}
    </span>
  );

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (Date.parse(String(nextGiveUpTime)) < Date.parse(String(new Date()))) {
      setGiveUpAvailable(true);
    } else {
      interval = setInterval(() => {
        if (Date.parse(String(nextGiveUpTime)) < Date.parse(String(new Date()))) {
          setGiveUpAvailable(true);
          clearInterval(interval);
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [nextGiveUpTime]);

  return (
    <Box display="flex" width="100%" flexDirection="column">
      <Box mt={2}>
        <Box>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Box mr={2}>
              <Button
                disabled={!isGiveUpAvailable}
                className="hint-btn"
                onClick={() => setPopupOpen(true)}
                variant="contained"
                size="medium"
                color="primary">
                Pass the Question
              </Button>
            </Box>
            {!isGiveUpAvailable && (
              <Box
                className="hint-timer">
                Pass available in
                <Countdown date={nextGiveUpTime} renderer={renderCountdown} />
              </Box>
            )}
            <HintDialog
              setPopupOpen={setPopupOpen}
              isPopupOpen={isPopupOpen}
              handleGiveUp={handleGiveUp}
              resetForm={resetForm}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GiveUpButton;
