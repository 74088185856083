import {gql} from '@apollo/client';

export const GET_CURRENT_USER = gql`
query {
    user {
        id 
        email
        login 
        urlIdentity
        role
    }
}
`
