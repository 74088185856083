import React from 'react';
import { Box, Button } from '@material-ui/core';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import './style.css';
import Answer from './Answer';

export interface IPuzzleComponent {
  answerText: string
  usedHints: string[]
  isPuzzleSolved: boolean
  quizNumber: number
  quizName: string
  eventId: number
  partPDF: string
  gameState: string
}

const PreviousPuzzle: React.FC<IPuzzleComponent> = (
  {
    answerText,
    usedHints,
    isPuzzleSolved,
    quizNumber,
    quizName,
    eventId,
    partPDF,
    gameState,
  },
) => {
  return (
    <Box display="flex" width="100%" flexDirection="column" mb={8}>
      <Box className="puzzle-title">
        Part {quizNumber}. <span>{quizName}</span>
      </Box>

      <Box className="puzzle-content" pt={2}>
        <Box className="puzzle-load">
          <Button
            className="pdf-button-wrap pdf-button"
            variant="contained"
            size="large"
            color="primary"
            component={'a'}
            href={`${process.env.REACT_APP_IMAGE_PATH}pdf/${eventId}/${partPDF}`}
            target="_blank"
            rel="noopener noreferrer"
            disabled={gameState == 'FINISHED' ? true : false}
          >
              Access puzzle <InsertDriveFileOutlinedIcon className="pdf-icon" />
          </Button>
        </Box>

        <Box className="puzzle-interact">
          <Answer
            answerText={answerText}
            isPuzzleSolved={isPuzzleSolved}
          />
          {usedHints.map((hint, index) => (
            <Box display="flex" width="100%" flexDirection="column" key={index}>
              <Box mt={2}>
                <Box>
                  <Box>
                    <Box className="hint-status">Hint {index + 1} Used</Box>
                    <Box className="hint-text">"{hint}"</Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PreviousPuzzle;
