import { ApolloClient, InMemoryCache, split, from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from "@apollo/client/link/error";

const token = localStorage.getItem('token');

const httpLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL,
    headers: token ? {authorization: `${token}`,'content-type': 'application/json'} : {'content-type': 'application/json'}
});
const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_GRAPHQL_SUBSCRIPTIONS || 'ws://api.localhost/subscriptions',
    options: {
      reconnect: true
    }
});

const onGraphQLError = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) console.log(`[Network error]: ${networkError}`);
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
                console.log(`[GraphQL error]: Message: ${message}`);
                if (message.indexOf('Context creation failed: jwt expired') !== -1) {
                    localStorage.setItem('token', '');
                    window.location.reload();
                }
            }
        );
    }
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const link = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    // TODO: add onError and setOnError
    // @ts-ignore
    httpLink
);


export default new ApolloClient({
    link: from([onGraphQLError, link]),
    cache: new InMemoryCache()
});
