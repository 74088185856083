import React from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import uuid from 'react-uuid';

const headCells = ['Event name', 'Customers', 'Date', 'Time', 'Notes', 'Status', 'Edit', 'View', 'Mail'];

const TablePart = () => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell className="admin-table-head" key={uuid()} align={'center'}>
            {headCell}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TablePart;