import React from 'react';
import {
  Grid,
  Typography,
  Container,
  Box,
  Button,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const Main: React.FC = () => {
  const items = [
    { id: 1, title: 'Admin Page', link: '/admin/admin' },
    { id: 2, title: 'Coordinator Page', link: '/event/admin' },
  ];

  return (
    <Container maxWidth="xs">
      <Box p={3}>
        <Typography variant="h4" align="center" gutterBottom>
          Navigation
        </Typography>

        <Grid container spacing={2}>
          {items.map((elem) => (
            <Grid item xs={12} key={elem.id}>
              <Button
                component={Link}
                to={elem.link}
                fullWidth
                color="primary"
                variant="contained"
                size="large"
              >
                {elem.title}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Main;


