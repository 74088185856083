import React from 'react';
import './style.css';

interface ICheckMark {
    isPuzzleSolved: boolean
}

const CheckMark: React.FC<ICheckMark> = (
    {
        isPuzzleSolved,
    },
) => {
    return (
        <svg className={`checkmark ${isPuzzleSolved ? "checkmark-green" : "checkmark-orange"}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className={`checkmark__circle ${isPuzzleSolved ? "checkmark__circle-green" : "checkmark__circle-orange"}`} cx="26" cy="26" r="25" fill="none"/>
            <path className={`checkmark__check ${isPuzzleSolved ? "checkmark__check-green" : "checkmark__check-orange"}`} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
        </svg>
    )
}

export default CheckMark;