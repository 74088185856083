import React from 'react';
import { Grid, Button, TextField } from '@material-ui/core';

const Form = props => {
  const {
    values: { email, password },
    errors,
    touched,
    handleSubmit,
    handleChange,
  } = props;

  return (
    <form className="auth-form" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            helperText={touched.email ? errors.email : ''}
            fullWidth
            label="Email"
            name="email"
            autoComplete="email"
            error={!!(errors.email)}
            value={email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            helperText={touched.password ? errors.password : ''}
            fullWidth
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            error={!!(errors.password)}
            value={password}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className="auth-btn"
      >
        Sign In
      </Button>
    </form>
  );
};

export default Form;
