import React, { useEffect, useState } from 'react';
import { Container, Box } from '@material-ui/core';
import CoordinatorPanel from '../../containers/CoordinatorPanel';
import CoordinatorHeader from '../../containers/CoordinatorHeader';
import { useQuery, useSubscription } from '@apollo/client';
import { GET_COORDINATOR_EVENT } from '../../operations/queries/getCoordinatorEvent';
import { UPDATE_GAME_STATE } from '../../operations/subscriptions/updateGameState';
import { getEventState, getEventUrlCode } from '../../utils/util';
import ErrorPage from '../../navigation/errors/ErrorPage';
import { Lines as Loader } from 'react-preloaders';

export default () => {
  const [gameState, setGameState] = useState('UPCOMING');

  const { loading, error, data, refetch } = useQuery(GET_COORDINATOR_EVENT, {
    variables: { url: getEventUrlCode() },
  });

  const {data: newGameState} = useSubscription(UPDATE_GAME_STATE, {
    variables: { url: getEventUrlCode() },
    onSubscriptionData: () => {},
  })

  useEffect(() => {
    if (data && data.currentTeamEvent) {
      if (gameState !== data.currentTeamEvent.event.state) {
        setGameState(data.currentTeamEvent.event.state);
      }
      if (!data.currentTeamEvent.event.state || data.currentTeamEvent.event.state === 'NULL') {
        setGameState(getEventState(data.currentTeamEvent.event));
      }
    }
  }, [data])

  useEffect(() => {
    if (newGameState) {
      setGameState(newGameState.updatedGameState);
    }
  }, [newGameState])

  if (loading) return <Loader background={'#333'} color="#fff" customLoading={loading} />;
  if (error) {
    return (
      <ErrorPage
        primaryText={'Wrong Coordinator URL'}
        secondaryText={'There\'s no page with this URL'}
      />
    );
  }

  if (data.coordinatorEvent.isCanceled) {
    return (
      <ErrorPage
        primaryText={'Canceled Event'}
        secondaryText={'Event with this URL was canceled'}
      />
    );
  }
  return (
    <Box minHeight="100vh">
      <Box display="flex" flexDirection="column">
        <Container>
          <Box>
            <CoordinatorHeader
              gameState={gameState}
              event={data.coordinatorEvent}
              eventRefetch={refetch}
            />
            <CoordinatorPanel
              gameState={gameState}
              event={data.coordinatorEvent}
              eventRefetch={refetch}
            />
          </Box>
        </Container>
      </Box>
    </Box>

  );
};
