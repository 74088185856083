import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions, 
} from '@material-ui/core';
import Moment from 'react-moment';
import Countdown, { CountdownRendererFn } from 'react-countdown';
import './style.css';
import { formatFinalTime, getPlaceEnding } from '../../../utils/util';
import smallFirstPlaceMedal from '../../../img/smallMedal_1.png';
import smallSecondPlaceMedal from '../../../img/smallMedal_2.png';
import smallThirdPlaceMedal from '../../../img/smallMedal_3.png';
import largeFirstPlaceMedal from '../../../img/largeMedal_1.png';
import largeSecondPlaceMedal from '../../../img/largeMedal_2.png';
import largeThirdPlaceMedal from '../../../img/largeMedal_3.png';
import tbaLogo from '../../../img/TBA_logo_orange.png';
import leaves from '../../../img/leaves.png';

interface IHeaderGameInfo {
  gameState: string
  startEventDateTime: Date
  endEventDateTime: Date
  eventName: string
  teamName: string
  solvedPuzzlesCount: number
  quizzesCount: number
  totalTime: number
  passedQuestionsCount: number
  usedHintsCount: number
  finishedTeamsCount: number
  teamsCount: number
  teamPlace: number
  score: number
}

const renderCountdown: CountdownRendererFn = ({ hours, minutes, seconds }) => (
  <span className="game-time-value">
    <span>{(hours < 10) ? '0' : ''}{hours}:{(minutes < 10) ? '0' : ''}{minutes}:{(seconds < 10) ? '0' : ''}{seconds}</span>
  </span>
);

const HeaderGameInfo: React.FC<IHeaderGameInfo> = (
  {
    gameState,
    startEventDateTime,
    endEventDateTime,
    eventName,
    teamName,
    solvedPuzzlesCount,
    quizzesCount,
    totalTime,
    passedQuestionsCount,
    usedHintsCount,
    finishedTeamsCount,
    teamsCount,
    teamPlace,
    score
  },
) => {
  const startAt = new Date(startEventDateTime);
  const [isEventEndPopupOpen, setEventEndPopupOpen] = useState(false);

  useEffect(() => {
    if (gameState === 'FINISHED') {
      setEventEndPopupOpen(true);
    }
  }, [gameState])

  return (
    <Box className="game-all-info" display="flex" justifyContent="space-between" alignItems="center" width="100%">
      <Box className="header-background-2">
        <img src={`${process.env.REACT_APP_IMAGE_PATH}img/topBG_image2.jpg`} alt='' />
      </Box>

      <Box className="event-info game-window">
        <Typography className="event-name" variant="h4">
          {eventName}
        </Typography>
        <Box className="team-info">
          <Box display="flex" className="team-title">
            <Typography className="team-value" variant="h5">
              <span>TEAM:</span> {teamName}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className="game-time-info">
        {gameState === 'UPCOMING' && (
          <Box className="time-to-wrap" display="flex" alignItems="center">
            <Box className="time-to">
              <Moment format='MMMM Do YYYY'>
                {startAt}
              </Moment>
            </Box>
            <Box className="time-to" ml={3}>
              <Moment format='hh:mm a'>
                {startAt}
              </Moment>
            </Box>
          </Box>
        )}
        {gameState === 'ACTIVE' && (
          <Box className="game-time" display="flex" flexDirection="column" alignItems="center">
            <Box className="game-time-text">
              TIME LEFT
            </Box>
            <Countdown date={endEventDateTime} renderer={renderCountdown} />
            <Box className="teams-done">
              TEAMS DONE: <span>{finishedTeamsCount} out of {teamsCount}</span>
            </Box>
          </Box>
        )}
        {gameState === 'FINISHED' && (
          <Box className="game-end" display="flex" alignItems="center">
            {teamPlace <= 3 && (
              <Box className="game-end-medal">
                {teamPlace === 1 && (
                  <img src={smallFirstPlaceMedal} alt='medal' />
                )}
                {teamPlace === 2 && (
                  <img src={smallSecondPlaceMedal} alt='medal' />
                )}
                {teamPlace === 3 && (
                  <img src={smallThirdPlaceMedal} alt='medal' />
                )}
              </Box>
            )}
            <Box className="game-end-value" display="flex" flexDirection="column" alignItems="center">
              <Box className="game-end-text">
                Event is Over
              </Box>
              <Box className="game-end-place">
                {teamPlace}{getPlaceEnding(teamPlace)} Place
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {gameState !== 'UPCOMING' ? (
        <Box className="game-stat-info" display="flex" flexDirection="column">
            <Box className="game-stat-solved">
              SOLVED: <span>{solvedPuzzlesCount} out of {quizzesCount}</span>
            </Box>
            <Box className="game-stat-hints-and-passed-group" display="flex" flexDirection="row">
              <Box className="game-stat-hints">
                HINTS: <span>{usedHintsCount}</span>
              </Box>
              <Box className="game-stat-passed">
                PASSED: <span>{passedQuestionsCount}</span>
              </Box>
            </Box>
            <Box className="game-stat-score">
              SCORE: <span>{score}</span>
            </Box>
            {totalTime !== 0 && (
              <Box className="game-stat-finish-time">
                FINISHED IN: <span>{formatFinalTime(totalTime)}</span>
              </Box>
            )}
        </Box>
      ) : (
        <Box className="game-stat-info" display="flex" flexDirection="column"/>
      )}

      {/* Event end Popup */}
      <Dialog
        onClose={() => setEventEndPopupOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={isEventEndPopupOpen}
        maxWidth="sm"
        fullWidth
		    disableScrollLock
      >
        <DialogContent dividers>
          <Box className="end-popup" display="flex" flexDirection="column" alignItems="center">
            <Box className="end-popup-logo">
              <img src={tbaLogo} alt="logo"/>
            </Box>
            <Box mt={1} className="end-popup-title">
              Event is over
            </Box>
            <Box mt={1} className="end-popup-team-name">
              {teamName}
            </Box>
            <Box className="end-popup-award">
            {teamPlace <= 3 ? (
              <Box className="end-popup-award-medal">
                {teamPlace === 1 && (
                  <img src={largeFirstPlaceMedal} alt='medal' />
                )}
                {teamPlace === 2 && (
                  <img src={largeSecondPlaceMedal} alt='medal' />
                )}
                {teamPlace === 3 && (
                  <img src={largeThirdPlaceMedal} alt='medal' />
                )}
              </Box>
            ) : (
              <Box className="end-popup-award-leaves-block">
                <Box className="end-popup-award-leaves">
                  <Box className="end-popup-award-place"><Box>{teamPlace}</Box></Box>
                  <img src={leaves} alt="leaves"/>
                </Box>
              </Box>
            )}
            </Box>
            <Box className="end-popup-team-place">
              {teamPlace}{getPlaceEnding(teamPlace)} Place
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            onClick={() => setEventEndPopupOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HeaderGameInfo;
