import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';

export default ( 
    {
        event,
        teamEvents,
    },
) => {
    const [fileDownloadURL, setFileDownloadURL] = useState('');
    const downloadLinkRef = React.createRef();

    useEffect(() => {
        if (fileDownloadURL !== '') {
            downloadLinkRef.current.click()
        } 
    }, [fileDownloadURL])

    const download = () => {
        makeXSV();
    }

    const makeXSV = () => {
        let content = '';
        teamEvents.forEach((teamEvent, index) => {
            content += (index + 1) + '|';
            content += teamEvent.team.name + '|';
    
            if (teamEvent.team.members.length !== 0) {
                const members = [];
                for (const member of teamEvent.team.members) {
                    if (member.name !== '') {
                        members.push(member.name);
                    }
                }
                content += members.join(',') + '|';
            }

            content += window.location.origin + '/event/' + teamEvent.url
    
            content += '\n';    
        });
        const blob = new Blob([content]);
        const urlToFile = URL.createObjectURL(blob);
        setFileDownloadURL(urlToFile);
    }

    return (
        <Box className="download-XSV-button">
            <a 
                className="download-XSV-blob-link-hidden"
                download={event.name + ' Teams.xsv'}
                href={fileDownloadURL}
                ref={downloadLinkRef}
            >
            </a>
            
            <Button
                className="download-teams-btn"
                variant="contained"
                color="primary"
                size="small"
                type="submit"
                onClick={() => {
                    download();
                }}
            >
                Download Teams
            </Button>
        </Box>
    )
}