import React from 'react';
import { Avatar, Typography, Container, Box } from '@material-ui/core';
import { Formik } from 'formik';
import FormComponent from './form';
import { validationSchema } from './validation';
import { parseError } from '../../utils/parseGraphqlError';
import { LOGIN_USER } from './graphql';
import './style.css';
import apolloClient from '../../utils/apolloClient';
import { getAdminUrlCode } from '../../utils/util';

const AdminForm = () => (
  <Container component="main" maxWidth="xs">
    <Box display="flex" flexDirection="column" alignItems="center" pt={10}>
      <Avatar className="avatar" />
      <Typography component="h1" variant="h5">Administrator</Typography>
      <Formik
        initialValues={{ password: '' }}
        validationSchema={validationSchema}
        onSubmit={({ password }, { setFieldError }) => {
          apolloClient.query({
            query: LOGIN_USER,
            variables: {
              urlIdentity: getAdminUrlCode(),
              password,
              role: 'admin',
            },
          })
            .then(({ data }) => {
              if (!data.loginUser) {
                return;
              }
              const token = data.loginUser.token;
              localStorage.setItem('token', token);
              window.location.href = '/admin';
            })
            .catch(error => {
              const errorObject = parseError(error);
              setFieldError('password', errorObject.text);
            });
        }}
      >
        {props => <FormComponent {...props} />}
      </Formik>
    </Box>
  </Container>
);


export default AdminForm;
