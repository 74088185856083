import React from 'react';
import { TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { Create, MailOutline, ControlPoint } from '@material-ui/icons';
import uuid from 'react-uuid';

export default (
  {
    setCreatePopupOpen,
    setTeamEventToEdit,
    setTeamEventToCopy,
    gameState,
    teamEvents,
    teamsLimit,
  },
) => {
  const emptyTeams = new Array(teamsLimit - teamEvents.length).fill(true);

  return (
    <TableBody>
      {teamEvents.map((teamEvent, index) => {
        return (
          <TableRow hover key={uuid()}>
            <TableCell align="center">
              {index + 1}
            </TableCell>
            <TableCell align="center">
              {teamEvent.team.name}
            </TableCell>
            <TableCell align="center">
              <ul className="members-list">
                {teamEvent.team.members
                  .filter(member => member.name)
                  .map((member, index) => (
                    <li key={index}>
                      {`${index + 1}. ${member.name}`}
                    </li>
                  ))
                }
              </ul>
            </TableCell>
            <Tooltip title={teamEvent.team.description || ''} placement="top" arrow>
              <TableCell align="center" className="description">
                {teamEvent.team.description}
              </TableCell>
            </Tooltip>
            <TableCell align="center">
              <a
                className="event-link"
                rel="noopener noreferrer"
                target="_blank"
                href={window.location.origin + '/event/' + teamEvent.url}
              >
                {window.location.origin + '/event/' + teamEvent.url}
              </a>
            </TableCell>
            <TableCell className={gameState !== 'UPCOMING' ? 'disabled' : ''} align="center">
              <Create
                onClick={() => setTeamEventToEdit(index)}
                className='admin-table-controls'
              />
            </TableCell>
            <TableCell className={gameState !== 'UPCOMING' ? 'disabled' : ''} align="center">
              <MailOutline
                onClick={() => setTeamEventToCopy(index)}
                className="admin-table-controls"
              />
            </TableCell>
          </TableRow>

        );
      })}
      {emptyTeams.map(() => (
        <TableRow hover key={uuid()}>
          <TableCell className={gameState !== 'UPCOMING' ? 'disabled' : ''} align="center">
            <ControlPoint
              onClick={() => setCreatePopupOpen(true)}
              className='admin-table-controls'
            />
          </TableCell>
          <TableCell colSpan={4}/>
          <TableCell className="disabled" align="center">
            <Create className='admin-table-controls' />
          </TableCell>
          <TableCell className="disabled" align="center">
            <MailOutline className="admin-table-controls" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}








