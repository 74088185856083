import React from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 3
}

export default class Fireworks extends React.Component {
  constructor(props) {
    super(props);
    this.animationInstance = null;
  }

  getAnimationSettings(originXA, originXB) {
    return {
      startVelocity: 50,
      spread: 360,
      ticks: 80,
      zIndex: 0,
      particleCount: 250,
      origin: {
        x: randomInRange(originXA, originXB),
        y: Math.random() - 0.2
      }
    }
  }

  componentDidUpdate() {
    if (this.props.runFirework) {

      let timerId = setInterval(() => {
        this.animationInstance && this.animationInstance(this.getAnimationSettings(Math.random(), Math.random()));
      }, 1300);
      
      setTimeout(() => { 
        clearInterval(timerId); 
      }, 10000);
      
    }
  }

  getInstance = (instance) => {
    this.animationInstance = instance
  }

  render() {
    return (
        <ReactCanvasConfetti refConfetti={this.getInstance} style={canvasStyles}/>
    );
  }
}